import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { getRequest } from '../../utils/http-helper';
import { attachParams } from '../../utils/constants';
import { GET_VIEW_ALL_LEADERBOARD } from '../../utils/url-helpers';

import './leaderboard.css';

import FullPageLoader from '../../pages/fullpage-loader/index';
import Modal from 'react-bootstrap/Modal';
import close from '../../assets/images/Close.svg';
import envelope from '../../assets/images/Envelope.svg';
import star from '../../assets/images/star.svg';
import userIcon from '../../assets/images/user_icon.svg';
import Select from 'react-select';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  {
    id: 'rankings',
    numeric: true,
    disablePadding: false,
    label: 'Rankings',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Email Id',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Role',
  },
  {
    id: 'phonenumber',
    numeric: false,
    disablePadding: false,
    label: 'Phone Number',
  },
  {
    id: 'points',
    numeric: true,
    disablePadding: false,
    label: 'Points',
  },
];

const calendarOptions = [
  { key: 0, value: 7, label: 'Last 7 days' },
  { key: 1, value: 14, label: 'Last 2 weeks' },
  { key: 2, value: 28, label: 'Last 4 weeks' },
  { key: 3, value: 42, label: 'Last 6 weeks' },
];

const colourStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#f1f1f1' : '#ffffff',
    border: isDisabled ? '' : '1px solid #CBCBCB',
    outline: isDisabled ? '' : '1px solid #CBCBCB',
    minHeight: '30px',
    padding: '0px',
    marginRight: '30px',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isDisabled ? '#f1f1f1' : '#ffffff',
      border: isDisabled ? '' : '1px solid #262261',
    },
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      backgroundColor: '#ffffff',
      color: '#606060',
      cursor: isDisabled ? 'not-allowed' : 'default',
      zIndex: 999,

      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#262261',
        color: '#ffffff',
      },
    };
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f1f1f1',
    color: '#262261',
    fontSize: '18px',
    padding: '10px 10px 10px 0px',
  },

  [`&.${tableCellClasses.body}`]: {
    padding: '10px 10px 10px 0px',
  },
}));

const LeaderboardTableHead = (props) => {
  const { order, orderBy } = props;

  return (
    <TableHead className="leaderboard-table-header">
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}>
              {headCell.label}
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
LeaderboardTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const Leaderboard = ({ showModal, onHide }) => {
  const [loaderShow, setLoaderShow] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('point');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [weeklyList, setWeeklyList] = useState(calendarOptions[0]);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalData, setTotalData] = useState([]);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    days: 7,
    page_size: 10,
    page_no: 1,
  });

  const filteredOptions = calendarOptions.filter((option) => option.value !== weeklyList.value);

  /**
   * ^ Function to handle the selection of a week in the UI.
   * @param {*} event - The event object representing the selected week
   */
  const onSelectWeek = (event) => {
    setWeeklyList(event);
    setPagination({ ...pagination, days: event.value });
  };

  /**
   * ^ Function to handle the change in the current page in the UI.
   * @param {*} event - The event object representing the page change
   * @param {*} newPage - The new page number
   */
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setPagination({ ...pagination, page_no: newPage + 1 });
  };

  /**
   * ^ Function to handle the change in the number of rows per page in the UI.
   * @param {*} e - The event object representing the change
   * @param {*} event - The value of the new number of rows per page
   */
  const handleRowsPerPageChange = (e, event) => {
    setPagination({ ...pagination, page_size: e.target.value });
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    viewAllUsersDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  /**
   * ^ Function to send a GET request to retrieve weekly leaderboard data.
   * @param {*} queryparams - The query parameters for the GET request
   * @returns {Promise<Object>} Weekly leaderboard data
   */
  const getWeeklyLeaderboardDataRequest = async (queryparams) => {
    try {
      let url = `${GET_VIEW_ALL_LEADERBOARD}`;
      let queryUrl = attachParams(url, queryparams);
      return await getRequest({
        url: queryUrl,
        authHeader: true,
      });
    } catch (error) {
      return error;
    }
  };

  /**
   * ^ Function to handle the search functionality for table data.
   * @param {*} text - The search text input
   */
  const onSearchTableData = (text) => {
    setSearch(text);
    if (text !== '') {
      const options = {
        keys: ['name', 'phone_number', 'email'],
      };
      const fuse = new Fuse(totalData, options);
      const result = fuse.search(text);

      setLeaderboardData(result.map((item) => item.item));
    } else {
      setLeaderboardData(totalData);
    }
  };

  /**
   * Function to view all user details and update the state accordingly.
   */
  const viewAllUsersDetail = async () => {
    const { data, error, status } = await getWeeklyLeaderboardDataRequest(pagination);
    setLoaderShow(true);
    if (status === 200) {
      setLeaderboardData(data?.data);
      setTotalData(data?.data);
      setTotalCount(data?.total);
      setLoaderShow(false);
    } else if (error) {
      toast.error(error?.response?.data?.message);
      setLoaderShow(false);
    }
  };

  return (
    <Modal className="leaderboard" show={showModal} onHide={onHide} centered>
      <Modal.Header className="leaderboard-header">
        <div className="leaderboard-header-container">
          <div className="leaderboard-header-container-items">
            <span className="leaderboard-header-title">LEADERBOARD</span>
            <div className="form-search">
              <input
                id="search-input"
                className="search-input"
                type="text"
                placeholder="Search"
                onChange={(e) => onSearchTableData(e.target.value)}
              />
            </div>
          </div>

          <div className={'leaderboard-header-container-items'}>
            <Select
              classNamePrefix="select"
              options={filteredOptions}
              styles={colourStyles}
              onChange={(event) => {
                onSelectWeek(event);
              }}
              value={weeklyList}
            />
            <img className="leaderboard-close-icon" src={close} onClick={onHide} alt="close" />
          </div>
        </div>
      </Modal.Header>

      <Modal.Body className="leaderboard-body">
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%' }}>
            <TableContainer className="leaderboard-table-container">
              <Table stickyHeader aria-labelledby="tableTitle" aria-label="sticky table">
                <LeaderboardTableHead order={order} orderBy={orderBy} />
                <TableBody>
                  {stableSort(leaderboardData, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow tabIndex={-1} key={index}>
                          <StyledTableCell align="center">
                            <>
                              <img
                                className="leaderboard-star-icon"
                                style={{
                                  visibility: [1, 2, 3].includes(page * rowsPerPage + index + 1)
                                    ? 'visible'
                                    : 'hidden',
                                }}
                                src={star}
                                alt="star"
                              />
                              {page * rowsPerPage + index + 1}
                            </>
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <>
                              <img className="leaderboard-user-icon" src={userIcon} alt="profile" />
                              {row.name}
                            </>
                          </StyledTableCell>
                          <StyledTableCell align="left" style={{ width: '245px' }}>
                            <>
                              <img
                                className="leaderboard-envelope-icon"
                                src={envelope}
                                alt="envelope"
                              />
                              {row.email}
                            </>
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.role_id}</StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{
                              width: '200px',
                              paddingLeft: '20px',
                            }}>
                            {row.phone_number}
                          </StyledTableCell>
                          <StyledTableCell align="center">{row.count}</StyledTableCell>
                        </TableRow>
                      );
                    })}
                  {leaderboardData.length === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={12}>
                        No record available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
        <FullPageLoader loaderShow={loaderShow} />
      </Modal.Body>

      <Modal.Footer className="leaderboard-footer">
        {search === '' && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            style={{ backgroundColor: '#f1f1f1' }}
            labelDisplayedRows={(page) =>
              `Showing ${page.from}-${page.to === -1 ? page.count : page.to} of  ${page.count}`
            }
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default Leaderboard;
