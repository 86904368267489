import { header } from '../utils/constants';
import { getRequest, postRequest } from '../utils/http-helper';
import {
  NON_ADMIN_USERS_URL,
  UPDATE_CLUSTER_URL,
  UPDATE_USER_CLUSTER_URL,
} from '../utils/url-helpers';

export const getClusterDetails = async () => {
  try {
    return await getRequest({
      url: NON_ADMIN_USERS_URL,
      headers: header,
      authHeader: true,
    });
  } catch (e) {
    return e;
  }
};

export const updateCluster = async (payload) => {
  try {
    return await postRequest({
      url: UPDATE_CLUSTER_URL,
      data: payload,
      headers: header,
      authHeader: true,
    });
  } catch (e) {
    return e;
  }
};

export const updateUserCluster = async (payload) => {
  try {
    return await postRequest({
      url: UPDATE_USER_CLUSTER_URL,
      data: payload,
      headers: header,
      authHeader: true,
    });
  } catch (e) {
    return e;
  }
};
