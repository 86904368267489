import './map-controls.css';

import zoom_in from '../../assets/images/zoom_in.svg';
import zoom_out from '../../assets/images/zoom_out.svg';

const MapControls = ({ data }) => {
  const mapZoom = (zoomValue) => {
    const view = data.getView();
    const currentzoom = data.getView().getZoom();
    view.animate({
      zoom: currentzoom + zoomValue,
      duration: 2000,
    });
  };

  return (
    <div className="map-controls-section">
      <div className="map-controls">
        <img onClick={() => mapZoom(+1)} src={zoom_in} alt="zoom-in" className="zoom-in-control" />
      </div>
      <div className="map-controls">
        <img
          onClick={() => mapZoom(-1)}
          src={zoom_out}
          alt="zoom-out"
          className="zoom-out-control"
        />
      </div>
    </div>
  );
};

export default MapControls;
