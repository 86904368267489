// Request methods & other constants.
export const CONTENT_TYPE_JSON = 'application/json';

// Async variable names.
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USERNAME = 'username';
export const USEREMAIL = 'useremail';
export const USER_ID = 'user_id';
export const COMPANY_ID = 'company_id';
export const USERGROUP_ID = 'user_group_id';
export const PHONE_NUM_REGEX = /^\d{10}$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/;

export const USER_TYPE = {
  1: 'Admin',
  2: 'Manager',
  3: 'Field Officers',
  4: 'Public',
};

export const header = {
  'Content-Type': 'application/json',
  accept: 'application/json',
};

export const ADMIN = 1;
export const MANAGER = 2;
export const FIELD_OFFICER = 3;
export const PUBLIC_USER = 4;

export const attachParams = (baseUrl, params) => {
  const url = new URL(baseUrl);
  url.search = new URLSearchParams(params).toString();
  return url;
};
