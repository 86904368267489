import React from 'react';
import { Link } from 'react-router-dom';

import './landing-page.css';
import Header from '../../pages/header';
import sparta_main_logo from '../../assets/images/logo/sparta_maps_logo.svg';
import google_play_logo from '../../assets/images/google_playstore.svg';
import SatsureLogo from '../../assets/images/logo/satsure-logo.svg';

const LandingPage = () => {
  return (
    <>
      <Header />

      <div className="main-content">
        <div className="landing-page-container">
          <div className="landing-page-content">
            <div className="landing-text-1">THE POWER OF DECISION INTELLIGENCE ON THE FIELD</div>
            <div className="landing-text-2">
              SATSURE SPARTA MAPS <br />
              WEB APP
            </div>
            <div className="landing-text-3">
              Sparta Maps is the spatial intelligence platform from SatSure. It enables the users,
              both regular and enterprise-level, to access the SatSure intelligence layers in a web
              & mobile device and also enhance the on-field work and experience
            </div>
            <div className="login-register">
              <Link to="/login">
                <button type="button" className="btn login-button" data-testid="login-button">
                  <strong>LOGIN</strong>
                </button>
              </Link>
              <Link to="/register">
                <button type="button" className="btn register-button" data-testid="register-button">
                  <strong>REGISTER</strong>
                </button>
              </Link>
            </div>
            <div className="google-play-lg">
              <p>Download our Mobile App</p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=co.satsure.spartamaps">
                <img className="google-play-logo" src={google_play_logo} alt="" />
              </a>
            </div>
          </div>
          <div className="satsure-sparta-logo">
            <div className="logo-wrapper">
              <img className="landing-page-logo" src={sparta_main_logo} alt="" />
            </div>
            <div className="powered-by-texts">
              <label>Powered By</label>
            </div>
            <div className="satsure-logo">
              <img src={SatsureLogo} alt="satsure logo" />
            </div>
          </div>
        </div>
        <div className="google-play-sm">
          <p>Download our Mobile App</p>
          <a href="https://media.satsure.co/sparta/app-release.apk" download>
            <img className="google-play-logo" src={google_play_logo} alt="playstore" />
          </a>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
