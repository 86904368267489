import React from 'react';
import { AddNewUser } from '../../pages';
import UserTable from '../../pages/user-management';
import ClusterTable from '../../pages/cluster-management';

function UserClusterManagementComponent({
  showUserTable,
  showClusterTable,
  showAddUserPage,
  setShowAddUserPage,
  search,
}) {
  return (
    <>
      {showAddUserPage ? (
        <AddNewUser setShowAddUserPage={setShowAddUserPage} />
      ) : (
        <>
          {showUserTable && <UserTable searchText={search} />}
          {showClusterTable && <ClusterTable searchText={search} />}
        </>
      )}
    </>
  );
}

export default UserClusterManagementComponent;
