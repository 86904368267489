import axios from 'axios';
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '../utils/localStorage';
import { REFRESH_TOKEN_URL } from '../utils/url-helpers';

let isRefreshTokenFetching = false;
let failedApi = [];

export const sendRequest = async (args) => {
  try {
    const { url, headers, authHeader, refreshHeader } = args;
    let headerParams;

    if (authHeader) {
      if (headers) {
        headerParams = {
          ...headers,
          Authorization: getAccessToken(),
        };
      } else {
        headerParams = {
          Authorization: getAccessToken(),
        };
      }
    }

    if (refreshHeader) {
      headerParams = {
        ...headers,
        Authorization: getRefreshToken(),
      };
    }

    const response = await axios({
      ...args,
      headers: headerParams,
      url: url,
    });
    return response;
  } catch (error) {
    const { config } = error;
    let status = error?.response?.status;
    let message = error?.response?.data?.msg;
    if (status === 401 && message === 'Token has expired' && !config.sent) {
      config.sent = true;

      if (!isRefreshTokenFetching) {
        failedApi.push(config);
        isRefreshTokenFetching = true;

        const newResponse = await refreshTokenRequest();

        const { data, status } = newResponse;
        if (status === 200) {
          setAccessToken(data?.data?.accessToken);
          setRefreshToken(data?.data?.refreshToken);

          if (failedApi.length > 0) {
            failedApi.map(async (api) => {
              api.headers['Authorization'] = data.data.accessToken;
              return await sendRequest(api);
            });
            isRefreshTokenFetching = false;
            failedApi = [];
          }
        } else {
          window.localStorage.clear();
          window.location.replace('/');
        }
      } else {
        failedApi.push(config);
      }
    }
    return { error, status };
  }
};

export const getRequest = async (args) => {
  const { data, headers, error, status } = await sendRequest({
    ...args,
    method: 'get',
  });
  if (status === 200) {
    return {
      data,
      error: null,
      headers,
      status,
    };
  }
  return {
    data,
    error: error || data,
    status,
  };
};

export const postRequest = async (args) => {
  const { data, headers, error, status } = await sendRequest({
    ...args,
    method: 'post',
  });

  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status,
    };
  }
  return {
    data,
    error,
    status,
  };
};

export const patchRequest = async (args) => {
  const { data, headers, error, status } = await sendRequest({
    ...args,
    method: 'patch',
  });
  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status,
    };
  }
  return {
    data: null,
    error: error || data,
    status,
  };
};

export const deleteRequest = async (args) => {
  const { data, error, status, headers } = await sendRequest({
    ...args,
    method: 'delete',
  });
  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status,
    };
  }
  return {
    data,
    error: error || data,
    status,
  };
};

export const putRequest = async (args) => {
  const { data, error, status, headers } = await sendRequest({
    ...args,
    method: 'put',
  });
  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status,
    };
  }
  return {
    data: null,
    error: error || data,
    status,
  };
};

const refreshTokenRequest = async () => {
  try {
    const header = {
      'Content-Type': 'application/json',
      accept: 'application/json',
    };
    header['Authorization'] = getRefreshToken();
    const response = await axios({
      url: REFRESH_TOKEN_URL,
      headers: header,
      method: 'POST',
    });
    return response;
  } catch (e) {
    return e;
  }
};
