import React, { useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Polygon from 'ol/geom/Polygon';
import { fromLonLat } from 'ol/proj';

// Images
import DescriptionIcon from '../../assets/images/description.svg';
import LocationIcon from '../../assets/images/my_location.svg';
import ArrowIcon from '../../assets/images/Down.svg';

import { getPopupImage } from '../../services/data-explorer-services';

// css
import './index.css';

const DataExplorerStatusList = ({
  gtPointList,
  mapObject,
  setModalPopUpStatus,
  approveReject,
  setSelectedStatusIds,
  selectedStatusIds,
  setIsApproveModalOpen,
  setSelectedDataForApproval,
}) => {
  const [isCloseOpen, setIsCloseOpen] = useState(false);

  const handleCheckboxChange = (id) => {
    setSelectedStatusIds((prevIds) => {
      if (!Array.isArray(prevIds)) {
        return [id];
      }

      return prevIds.includes(id) ? prevIds.filter((prevId) => prevId !== id) : [...prevIds, id];
    });
  };

  const handleSelectAllCheckBox = () => {
    const allStatusIds = gtPointList.map((status) => status?.user_farm_id);
    const allSelected = allStatusIds.every((id) => selectedStatusIds.includes(id));

    if (allSelected) {
      setSelectedStatusIds([]);
    } else {
      setSelectedStatusIds(allStatusIds);
    }
  };

  const markedPoint = (markedPointId) => {
    let view = mapObject.getView();

    if (markedPointId?.geometry?.type === 'Point') {
      view.animate({
        center: fromLonLat(markedPointId?.geometry?.coordinates),
        zoom: 20,
        duration: 2000, // Animation duration in milliseconds
      });
    } else if (markedPointId?.geometry?.type === 'Polygon') {
      const polygon = new Polygon(markedPointId?.geometry?.coordinates);

      const findPolygonCenter = (polygon) => {
        const interiorPoint = polygon.getInteriorPoint();
        return interiorPoint.getCoordinates();
      };

      const center = findPolygonCenter(polygon);

      view.animate({
        center: fromLonLat(center),
        zoom: 20,
        duration: 2000, // Animation duration in milliseconds
      });
    }
  };

  const fetchingFormData = async (polygonId, statusList) => {
    const { data, status, error } = await getPopupImage(polygonId);
    if (status === 200) {
      setIsApproveModalOpen(true);
      setSelectedDataForApproval(data);
      setModalPopUpStatus(statusList?.status);
      setSelectedStatusIds(statusList?.user_farm_id);
    }
    if (error) {
      toast.error(data.message);
    }
  };

  return (
    <ul className="data-explorer-status-list">
      <li className="approve-status-list">
        <label>Approved</label>
        <label>
          {(
            (gtPointList?.filter((item) => item.status === 'Approve').length * 100) /
            gtPointList?.length
          ).toFixed(2)}
          %
        </label>
        <span>
          {gtPointList?.filter((item) => item.status === 'Approve').length || 0}/
          {gtPointList?.length}
        </span>
      </li>
      <li className="approve-status-list">
        <label>Pending</label>
        <label>
          {(
            (gtPointList?.filter((item) => item.status === 'Pending' || item.status === null)
              .length *
              100) /
            gtPointList?.length
          ).toFixed(2)}
          %
        </label>
        <span>
          {gtPointList?.filter((item) => item.status === 'Pending' || item.status === null)
            .length || 0}
          /{gtPointList?.length}
        </span>
      </li>
      <li className="approve-status-list">
        <label>Rejected</label>
        <label>
          {(
            (gtPointList?.filter((item) => item.status === 'Rejected').length * 100) /
            gtPointList?.length
          ).toFixed(2)}
          %
        </label>
        <span>
          {gtPointList?.filter((item) => item.status === 'Rejected').length || 0}/
          {gtPointList?.length}
        </span>
      </li>
      <li className="marked-list-sections">
        <div className={`close-list ${isCloseOpen ? 'open' : 'close'}`}>
          <div
            className="close-txt"
            onClick={() => {
              setIsCloseOpen(false);
            }}>
            <h1>Close List</h1>
            <div className="arrow">
              <img src={ArrowIcon} alt="arrow" />
            </div>
          </div>
          <div className="marked-select">
            <h2 className="marked-point">Point Marked</h2>
            <h3 className="select-all" onClick={handleSelectAllCheckBox}>
              Select All
            </h3>
          </div>
          <ul className="marked-point-list">
            {gtPointList
              ?.filter((item) => item.status === 'Pending' || item.status === null)
              .map((statusList, index) => (
                <li key={statusList?.user_farm_id}>
                  <span>
                    {statusList?.properties?.marked_by}_
                    {moment(statusList?.properties?.date).format('DD MMM YYYY')}
                  </span>
                  <label>
                    <img
                      src={DescriptionIcon}
                      alt="descriptio"
                      onClick={() => {
                        const polygonId = statusList?.properties?.polygon_id;
                        fetchingFormData(polygonId, statusList);
                      }}
                    />
                  </label>
                  <label
                    data-testid={`location-icon-label-${index}`}
                    onClick={() => {
                      markedPoint(statusList);
                    }}>
                    <img src={LocationIcon} alt="descriptio" />
                  </label>
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      id={`checkbox-${statusList?.user_farm_id}`}
                      checked={selectedStatusIds.includes(statusList?.user_farm_id)}
                      onChange={() => handleCheckboxChange(statusList?.user_farm_id)}
                    />
                  </div>
                  <p>
                    {statusList?.properties?.marked_by}_
                    {moment(statusList?.properties?.date).format('DD MMM YYYY _ h:mm:ss a')}
                  </p>
                </li>
              ))}
          </ul>
          {gtPointList?.filter((item) => item.status === 'Pending' || item.status === null).length >
            0 && (
            <div className="reject-approve-btn">
              <button
                className="list-btn reject"
                onClick={() => {
                  approveReject('Rejected');
                  setIsCloseOpen(false);
                }}
                disabled={!selectedStatusIds?.length}>
                Reject
              </button>
              <button
                className="list-btn approve"
                onClick={() => {
                  approveReject('Approve');
                  setIsCloseOpen(false);
                }}
                disabled={!selectedStatusIds?.length}>
                Approve
              </button>
            </div>
          )}
        </div>
        <div
          className="open-list"
          onClick={() => {
            setIsCloseOpen(true);
          }}>
          <h1>Open List</h1>
          <div className="arrow up-arrow">
            <img src={ArrowIcon} alt="arrow" />
          </div>
        </div>
      </li>
    </ul>
  );
};
export default DataExplorerStatusList;
