import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Select, { components } from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-datepicker/dist/react-datepicker.css';

import calender from '../../assets/images/calendar.svg';
import OptionIcon from '../../assets/images/option-icon.svg';

import { onCreateTask, onGetCluster } from '../../services/task-services';

import './datavalidation.css';

const DataValidation = ({ geometry, getTaskOpenStatus, setFileSelected, setTrigger, trigger }) => {
  const [clusterOption, setClusterOption] = useState([]);
  const [taskName, setTaskName] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedInputs, setSelectedInputs] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedCluster, setSelectedCluster] = useState(null);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);

  const { Option } = components;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data, status } = await onGetCluster();

    if (status === 200) {
      setClusterOption(
        data.data.map((item) => {
          return {
            value: item.cluster_id,
            label: item.cluster_name,
          };
        })
      );
    }
  };

  const IconOption = (props) => (
    <Option {...props}>
      <div className="option-label">
        <span className="option-image-wrapper">
          <img src={OptionIcon} alt="Option" />
        </span>
        <label title={props.label}>{props.label}</label>
      </div>
    </Option>
  );

  /**
   * Clears all input fields and resets the state for creating a task
   */
  const onClear = () => {
    setTaskName('');
    setSelectedInputs({ startDate: null, endDate: null });
    setSelectedCluster(null);
    setIsCreateButtonDisabled(true);
  };

  /**
   * Handles the submission of the task creation form
   */
  const onSubmitCreateTask = async () => {
    if (
      taskName !== '' &&
      selectedCluster?.value &&
      selectedInputs?.startDate &&
      selectedInputs.endDate
    ) {
      setLoading(true);
      let payload = {
        task_name: taskName,
        company_id: localStorage.getItem('company_id'),
        status: 2,
        geometry: geometry,
        cluster_id: [selectedCluster?.value],
        start_date: moment(selectedInputs.startDate).format('YYYY-MM-DD'),
        end_date: moment(selectedInputs.endDate).format('YYYY-MM-DD'),
      };
      const { status, error } = await onCreateTask(payload);
      if (status === 200) {
        toast.success('Task Created Successfully.');
        getTaskOpenStatus(false);
        setFileSelected(false);
        setLoading(false);
        setTrigger(!trigger);
      }
      if (error) {
        toast.error('Data already exist');
        setLoading(false);
      }
    }
  };

  return (
    <div className="data-validation-container">
      <div className="data-validation">
        <div className="data-validation-header">
          <div className="header-wrapper">
            <div className="header-left">
              <label className="header-text">Uploaded File</label>
              <label className="header-text active">Data Validation Plan 1</label>
            </div>
            <div className="header-right">
              <button
                className={isCreateButtonDisabled ? 'create-btn-disabled' : 'create-btn'}
                onClick={() => onSubmitCreateTask()}>
                {loading ? (
                  <span className="circle-profress">
                    <CircularProgress color="inherit" />
                  </span>
                ) : (
                  'Create'
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="tasking-clear-btn-div">
          <button className="tasking-clear-btn" onClick={onClear}>
            Clear All
          </button>
        </div>
      </div>

      <div className="form-selection-wrapper">
        <div className="form-selecton-group">
          <div className="select-group">
            <label htmlFor="">Task Name</label>
            <hr />
            <div>
              <input
                className="select-group-input"
                placeholder="Enter Task"
                value={taskName}
                onChange={(e) => {
                  if (
                    e.target.value.length !== 0 &&
                    selectedCluster !== null &&
                    selectedInputs.startDate !== null &&
                    selectedInputs.endDate !== null
                  ) {
                    setIsCreateButtonDisabled(false);
                  } else {
                    setIsCreateButtonDisabled(true);
                  }
                  setTaskName(e.target.value);
                }}
                type="text"></input>
            </div>
          </div>
          <div className="select-group">
            <label htmlFor="">Assigned Project</label>
            <hr />
            <div>
              <Select
                classNamePrefix="create-task react-select"
                placeholder="Select Project"
                value={selectedCluster}
                options={clusterOption}
                isSearchable={true}
                components={{ Option: IconOption }}
                onChange={(e) => {
                  if (
                    e.length !== 0 &&
                    taskName !== '' &&
                    selectedInputs.startDate !== null &&
                    selectedInputs.endDate !== null
                  ) {
                    setIsCreateButtonDisabled(false);
                  } else {
                    setIsCreateButtonDisabled(true);
                  }
                  setSelectedCluster(e);
                }}
              />
            </div>
          </div>
          <div className="select-group">
            <label htmlFor="">Start Date</label>
            <hr />
            <label className="datepicker-label">
              <DatePicker
                placeholderText="dd-mm-yyyy"
                showPopperArrow={true}
                selected={selectedInputs.startDate}
                startDate={selectedInputs.startDate}
                shouldCloseOnSelect={true}
                endDate={selectedInputs.endDate}
                onChange={(date, e) => {
                  //to close on selecting
                  if (e && typeof e.preventDefault === 'function') {
                    e.preventDefault();
                  }

                  setIsCreateButtonDisabled(true);

                  setSelectedInputs((prev) => {
                    return {
                      ...prev,
                      startDate: date,
                      endDate: null,
                    };
                  });
                }}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                useShortMonthInDropdown
                // disabled={selectedInputs.formId.value !== '' ? false : true}
                dateFormat="dd-MM-yyyy"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />

              <img src={calender} alt="calender" className="calender" />
            </label>
          </div>
          <div className="select-group">
            <label htmlFor="">End Date</label>
            <hr />
            <label className="datepicker-label">
              <DatePicker
                placeholderText="dd-mm-yyyy"
                showPopperArrow={false}
                selected={selectedInputs.endDate}
                onChange={(date, e) => {
                  if (e && typeof e.preventDefault === 'function') {
                    e.preventDefault();
                  }
                  if (
                    selectedCluster !== null &&
                    taskName !== '' &&
                    selectedInputs.startDate !== null
                  ) {
                    setIsCreateButtonDisabled(false);
                  } else {
                    setIsCreateButtonDisabled(true);
                  }
                  setSelectedInputs((prev) => {
                    return {
                      ...prev,
                      endDate: date,
                    };
                  });
                }}
                endDate={selectedInputs.endDate}
                minDate={selectedInputs.startDate}
                shouldCloseOnSelect={true}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                useShortMonthInDropdown
                // disabled={selectedInputs.formId.value !== '' ? false : true}
                dateFormat="dd-MM-yyyy"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
              <img src={calender} alt="calender" className="calender" />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataValidation;
