// constant
import * as constants from './constants';

export const setAccessToken = (accessToken) => {
  localStorage.setItem(constants.ACCESS_TOKEN, accessToken);
};

export const getAccessToken = () => {
  return localStorage.getItem(constants.ACCESS_TOKEN);
};

export const setUserId = (userId) => {
  localStorage.setItem(constants.USER_ID, userId);
};

export const getUserId = () => {
  return localStorage.getItem(constants.USER_ID);
};

export const getRefreshToken = () => {
  return localStorage.getItem(constants.REFRESH_TOKEN);
};

export const setRefreshToken = (refreshToken) => {
  localStorage.setItem(constants.REFRESH_TOKEN, refreshToken);
};

export const getUsername = () => {
  return localStorage.getItem(constants.USERNAME);
};

export const setUsername = (username) => {
  localStorage.setItem(constants.USERNAME, username);
};

export const getUseremail = () => {
  return localStorage.getItem(constants.USEREMAIL);
};

export const getCompanyId = () => {
  return localStorage.getItem(constants.COMPANY_ID);
};

export const getUserGroupId = () => {
  return localStorage.getItem(constants.USERGROUP_ID);
};

export const setUserGroupId = (user_group_id) => {
  localStorage.setItem(constants.USERGROUP_ID, user_group_id);
};

export const removeAllCookies = () => {
  window.localStorage.clear();
};
