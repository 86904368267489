import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../pages/header';

import './register.css';

import passwordshow from '../../assets/images/Eye.svg';
import passwordhide from '../../assets/images/Eye-closed.svg';
import sparta_main_logo from '../../assets/images/logo/sparta_maps_logo.svg';
import SatsureLogo from '../../assets/images/logo/satsure-logo.svg';
import Info from '../../assets/images/icons/Info-circle.svg';

import { onRegister } from '../../services/auth-services';
import { isValidPhoneNumber, isValidEmail, isPasswordValid } from '../../utils/regex-helper';

const Register = () => {
  let navigate = useNavigate();
  const [hideShowPwd, setHideShowPwd] = useState(true);
  const [hideShowReenterPwd, setHideShowReenterPwd] = useState(true);
  const [error, setError] = useState({
    name: false,
    email: false,
    password: false,
    phoneNumber: false,
  });

  const [registerData, setRegisterData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
  });
  const [isRegisterDisabled, setIsRegisterDisabled] = useState(true);

  /**
   * ^ Handles the change event for input fields
   * @param {*} event - The event object
   */
  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setRegisterData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setError((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });
  };

  /**
   * Checks the form inputs for validation
   */
  const checkFormInputs = () => {
    let isValid = true;
    if (!isValidPhoneNumber(registerData.phoneNumber)) {
      toast.error('Please Enter a Valid Phone Number');
      isValid = false;
      setError((prev) => {
        return {
          ...prev,
          phoneNumber: true,
        };
      });
    }
    if (!isValidEmail(registerData.email)) {
      toast.error('Please Enter a Valid Email');
      isValid = false;
      setError((prev) => {
        return {
          ...prev,
          email: true,
        };
      });
    }
    if (!isPasswordValid(registerData.password)) {
      toast.error(
        'Password should be atleast 8 characters and must contain Upper Case, Lower Case, Number and Special Character'
      );
      isValid = false;
      setError((prev) => {
        return {
          ...prev,
          password: true,
        };
      });
    }
    if (registerData.password !== registerData.confirmPassword) {
      toast.error('Password did not match');
      isValid = false;
      setError((prev) => {
        return {
          ...prev,
          password: true,
        };
      });
    }
    if (isValid) {
      sendRegisterRequest();
    }
  };

  /**
   * ^ Handles the submit event for the form
   * @param {*} event - The event object
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    checkFormInputs();
  };

  /**
   *  Sends a register request with the provided data
   */
  const sendRegisterRequest = async () => {
    let payload = {
      name: registerData.name,
      phone: registerData.phoneNumber,
      email: registerData.email,
      password: registerData.password,
      company_id: 0,
      user_group_id: 4,
      cluster_id: [],
      row_id: 0,
      bulk: false,
    };

    let data = {
      data: [payload],
    };

    const { status, error } = await onRegister(data);

    if (status === 200) {
      toast.success('User Successfully Registered');
      navigate('/login');
    } else if (status === 401) {
      toast.error(error?.response?.data?.message);
    }
  };

  /**
   * Updates the registration form's disabled state based on input fields
   */
  useEffect(() => {
    const { name, email, password, confirmPassword, phoneNumber } = registerData;
    const allFieldsFilled =
      name.trim() !== '' &&
      email.trim() !== '' &&
      password.trim() !== '' &&
      confirmPassword.trim() !== '' &&
      phoneNumber.trim() !== '';

    setIsRegisterDisabled(!allFieldsFilled);
  }, [registerData]);

  return (
    <div className="landing-constainer">
      <Header />
      <div className="common-register-container register-container">
        <div className="sparta-logo-section-register">
          <div className="satsure-sparta-logo">
            <div className="logo-wrapper">
              <img className="landing-page-logo" src={sparta_main_logo} alt="Logo" />
            </div>
            <div className="powered-by-texts">
              <label>Powered By</label>
            </div>
            <div className="satsure-logo">
              <img src={SatsureLogo} alt="satsure logo" />
            </div>
          </div>
        </div>
        <div className="register-form-card">
          <div className="register-form-container">
            <p className="register-header">REGISTER</p>
            <Form className="registration-form" onSubmit={handleSubmit}>
              <Form.Group className="registraion-form-group">
                <Form.Label className="register-form-label">
                  Name<span className="input-mandatory">*</span>
                </Form.Label>
                <Form.Control
                  className="register-input first-name"
                  name="name"
                  type="text"
                  placeholder="Name"
                  required
                  onChange={(e) => onChangeHandler(e)}
                />
              </Form.Group>
              <Form.Group
                className="registraion-form-group register-email"
                controlId="formBasicEmail">
                <Form.Label className="register-form-label">Email</Form.Label>
                <span className="input-mandatory">*</span>
                <Form.Control
                  className="register-input"
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  required
                  onChange={(e) => onChangeHandler(e)}
                />
              </Form.Group>
              <Form.Group
                className="registraion-form-group register-password"
                controlId="formBasicPassword">
                <Form.Label className="register-form-label">Password</Form.Label>
                <span className="input-mandatory">*</span>

                <div className="hover-text">
                  <img src={Info} alt="Info" />
                  <span className="tooltip-text" id="right">
                    Must contain atleast 8 characters
                    <br />
                    Must contain atleast one Upper case letter
                    <br />
                    Must contain atleast one Lower case letter
                    <br />
                    Must contain atleast one number
                    <br />
                    Must contain atleast one special character
                    <br />
                  </span>
                </div>

                <div className="register-password-div">
                  <Form.Control
                    className="register-input password-input"
                    name="password"
                    type={`${hideShowPwd ? 'password' : 'text'}`}
                    placeholder="Password"
                    required
                    onChange={(e) => onChangeHandler(e)}
                  />
                  <img
                    src={hideShowPwd ? passwordhide : passwordshow}
                    alt="loginLogo"
                    onClick={() => setHideShowPwd((prev) => !prev)}
                    className="password-eye"
                  />
                </div>
              </Form.Group>
              <Form.Group className="registraion-form-group register-confirm-password">
                <Form.Label className="register-form-label">Re-enter Password</Form.Label>
                <span className="input-mandatory">*</span>
                <div className="register-confirm-password-div">
                  <Form.Control
                    className="register-input password-input"
                    name="confirmPassword"
                    type={`${hideShowReenterPwd ? 'password' : 'text'}`}
                    placeholder="Password"
                    required
                    onChange={(e) => onChangeHandler(e)}
                  />
                  <img
                    src={hideShowReenterPwd ? passwordhide : passwordshow}
                    alt="loginLogo"
                    onClick={() => setHideShowReenterPwd((prev) => !prev)}
                    className="password-eye"
                  />
                </div>
              </Form.Group>
              <Form.Group className="registraion-form-group">
                <Form.Label className="register-form-label">Phone Number</Form.Label>
                <span className="input-mandatory">*</span>
                <Form.Control
                  className="register-input"
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone number"
                  required
                  onChange={(e) => onChangeHandler(e)}
                />
              </Form.Group>
              <div className="sign-in-register">
                Already have an account?
                <Link className="sign-in-link" to="/login">
                  Sign In
                </Link>
              </div>
              <button
                type="submit"
                className="btn register-submit-button"
                disabled={isRegisterDisabled}>
                <strong>Register</strong>
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
