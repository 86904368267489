import React, { useEffect, useRef } from 'react';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import 'ol/ol.css';

import MapControls from '../../pages/map-controls';
import sparta_map_logo from '../../assets/images/logo/sparta_maps_logo.svg';

import './map-component.css';

function MapComponent({ mapObject, setMap }) {
  const mapElement = useRef(null);
  const mapRef = useRef(null);
  mapRef.current = mapObject;

  useEffect(() => {
    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
          }),
        }),
      ],
      view: new View({
        center: [8804131.622620502, 2409371.619506935],
        zoom: 5,
      }),
      controls: [],
    });

    initialMap.on('loadstart', function () {
      initialMap.getTargetElement().classList.add('spinner');
    });
    initialMap.on('loadend', function () {
      initialMap.getTargetElement().classList.remove('spinner');
    });
    setMap(initialMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div ref={mapElement} id="map" className="map-container">
        {mapObject !== undefined && <MapControls data={mapObject} />}
        <div className="sparta-maps-watermark">
          <img
            className="sparta-watermark-logo"
            alt="satsure-logo-watermark"
            src={sparta_map_logo}
          />
        </div>
      </div>

      <div id="popup-ol"></div>
    </>
  );
}

export default MapComponent;
