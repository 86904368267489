import React, { useCallback } from 'react';
// CSS
import styles from './actionModal.module.css';

import Download from '../../assets/images/icons/download_all.svg';

const CropImageDownloader = ({ cropImagesURL }) => {
  const handleDownload = useCallback((imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = getFileNameFromUrl(imageUrl);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const getFileNameFromUrl = (url) => {
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1].split('?')[0];
  };

  return (
    <li>
      <img src={cropImagesURL.url} alt="crop img" />
      <button
        data-testid="download-btn"
        className={styles.download_btn}
        onClick={() => {
          handleDownload(cropImagesURL.url);
        }}>
        <img src={Download} alt="download all" />
      </button>
    </li>
  );
};

export default CropImageDownloader;
