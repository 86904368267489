import { createSlice } from '@reduxjs/toolkit';

const dataExplorerSlice = createSlice({
  name: 'explorer',
  initialState: {
    formData: [],
    clusterData: [],
    userData: [],
    formFieldsDetails: [],
  },
  reducers: {
    doGetFormClusterUserDetails(state, action) {
      const { payload } = action;

      state.formData = payload?.data?.forms_data || [];
      state.clusterData = payload?.data?.clusters_data || [];
      state.userData = payload?.data?.users_data || [];
    },

    doClearFormData(state) {
      state.formData = [];
    },
    doClearClusterData(state) {
      state.clusterData = [];
    },
    doClearUserDetails(state) {
      state.userData = [];
    },
    doGetFormDetails(state, action) {
      state.formFieldsDetails = action.payload || [];
    },
    doClearAllDataExplorer(state) {
      state.formData = [];
      state.clusterData = [];
      state.userData = [];
      state.formFieldsDetails = [];
    },
    doClearDataExplorer(state) {
      state.clusterData = [];
      state.userData = [];
      state.formFieldsDetails = [];
    },
  },
});

export const dataExplorerAction = dataExplorerSlice.actions;

export default dataExplorerSlice.reducer;
