import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import MobileAppSettings from '../../pages/mobile-app-settings';
import LayersSettings from '../../pages/layers-settings';
import PasswordSettings from '../../pages/password-settings';
import ProfileSettings from '../../pages/profile-settings';

import { getUserGroupId } from '../../utils/localStorage';
import * as constants from '../../utils/constants';
import './settings.css';

const Settings = () => {
  return (
    <Tabs>
      <TabList className="settings-tab-list">
        {getUserGroupId(constants.USERGROUP_ID) === '1' ? (
          <Tab className="settings-tab">Mobile App</Tab>
        ) : null}
        <Tab className="settings-tab">Profile</Tab>
        <Tab className="settings-tab">Password</Tab>
        <Tab className="settings-tab">Layers</Tab>
      </TabList>

      {getUserGroupId(constants.USERGROUP_ID) === '1' ? (
        <TabPanel>
          <div className="settings-tab-panel-container">
            <MobileAppSettings />
          </div>
        </TabPanel>
      ) : null}
      <TabPanel>
        <div className="settings-tab-panel-container">
          <ProfileSettings />
        </div>
      </TabPanel>
      <TabPanel>
        <div className="settings-tab-panel-container">
          <PasswordSettings />
        </div>
      </TabPanel>
      <TabPanel>
        <div className="settings-tab-panel-container">
          <LayersSettings />
        </div>
      </TabPanel>
    </Tabs>
  );
};

export default Settings;
