import { getRequest, postRequest } from '../utils/http-helper';
import { CREATE_TASK_URL, GET_CLUSTER_URL, GET_CREATE_TASK_LIST } from '../utils/url-helpers';
import { header, attachParams } from '../utils/constants';

export const getCreateTaskDataRequest = async (queryparams) => {
  try {
    let url = `${GET_CREATE_TASK_LIST}`;
    let queryUrl = attachParams(url, queryparams);
    return await getRequest({
      url: queryUrl,
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};
export const onGetCluster = async () => {
  try {
    let user_id = localStorage.getItem('user_id');
    let cluster_url = `${GET_CLUSTER_URL}/${user_id}/get-clusters`;
    return await getRequest({
      url: cluster_url,
      headers: header,
      authHeader: true,
    });
  } catch (e) {
    return e;
  }
};

export const onCreateTask = async (payload) => {
  try {
    return await postRequest({
      url: CREATE_TASK_URL,
      data: payload,
      headers: header,
      authHeader: true,
    });
  } catch (e) {
    return e;
  }
};
