import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

import sparta_logo from '../../assets/images/logo/sparta_maps_logo.svg';

const Header = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="landing-navbar-container">
      <div className="image-wrapper" onClick={handleLogoClick}>
        <img src={sparta_logo} alt="satsure-logo" />
      </div>
      <ul className="navbar-tab">
        <li className="navbar-list">About Us</li>
        <li className="navbar-list">FAQs</li>
        <li className="navbar-list">Contacts</li>
      </ul>
    </div>
  );
};

export default Header;
