import { components } from 'react-select';

const MultiSelect = (options) => {
  return (
    <div>
      <components.Option {...options}>
        <input type="checkbox" checked={options.isSelected} onChange={() => null} />
        <label>{options.label}</label>
      </components.Option>
    </div>
  );
};

export default MultiSelect;
