import React, { useRef } from 'react';
import toGeoJSON from '@mapbox/togeojson';

const UploadFile = ({ setFileSelected, setGeometry }) => {
  const inputRef = useRef(null);

  const onChangeFile = (event) => {
    const kmlParser = new DOMParser();
    const reader = new FileReader();
    if (event?.target?.files[0]) {
      setFileSelected(true);

      let file_name = event.target.files[0]?.name;
      let fileType = file_name.split('.')[1];

      reader.onload = async (event) => {
        const object = event.target.result;
        if (fileType === 'kml') {
          const kmlDocument = kmlParser.parseFromString(object, 'text/xml');
          const geoJSON = toGeoJSON.kml(kmlDocument);
          let value = geoJSON?.features.map((item) => item.geometry);
          setGeometry(value);
        } else if (fileType === 'geojson') {
          let json = JSON.parse(object);
          let value = json?.features.map((item) => item.geometry);
          setGeometry(value);
        }
      };
      reader.readAsText(event.target.files[0]);
    }
  };

  return (
    <div className="upload-file-container">
      <label className="upload-file-header">Upload GeoTagged Plan</label>
      <div className="file-input-wrapper">
        <label className="file-ref">
          Browse your file
          <input
            ref={inputRef}
            type="file"
            className="upload-file"
            accept=".geojson,.kml"
            onChange={(e) => onChangeFile(e)}
          />
        </label>
        <button onClick={() => inputRef.current.click()} className="select-button">
          Select
        </button>
      </div>
      <p className="note-text">Supported formats:kml,geojson</p>
    </div>
  );
};

export default UploadFile;
