import React from 'react';
import './setting-list-item.css';

const SettingsListItem = ({ title, description, children = null }) => (
  <div className="settings-list-item-container">
    <div className="settings-list-item-text-content">
      <span className="settings-list-item-title">{title}</span>
      <span className="settings-list-item-description">{description}</span>
    </div>
    <div>{children}</div>
  </div>
);

export default SettingsListItem;
