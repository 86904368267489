import { SETTINGS_DATA_URL, GET_COMPANY_SETTINGS } from '../utils/url-helpers';
import { getRequest, postRequest } from '../utils/http-helper';
import { getCompanyId } from '../utils/localStorage';

export const sendGetSettingsData = async () => {
  try {
    return await getRequest({
      url: GET_COMPANY_SETTINGS + getCompanyId(),
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};

export const sendSettingsRequest = async (payload) => {
  try {
    return await postRequest({
      url: SETTINGS_DATA_URL,
      data: payload,
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};
