import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, Form, ProgressBar } from 'react-bootstrap';
import './index.css';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import g_icon from '../../assets/images/greater_icon.svg';
import front_arrow from '../../assets/images/arow_forward.svg';
import Close from '../../assets/images/Close.svg';
import Papa from 'papaparse';

const colourStyles = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? '#f1f1f1' : '#ffffff',
    border: isDisabled ? '' : '1px solid #262261',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isDisabled ? '#f1f1f1' : '#ffffff',
      border: isDisabled ? '' : '1px solid #d3d3d3',
    },
  }),
  option: (styles, { isDisabled }) => {
    return {
      ...styles,
      backgroundColor: '#ffffff',
      color: '#606060',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#262261',
        color: '#ffffff',
      },
    };
  },
};

const UploadCsvModal = ({ isCSVModalOpen, userList, setUserList, setCSVModalOpen }) => {
  const headerRef = useRef(null);
  const [visibleSection, setVisibleSection] = useState();
  const [areFieldsEmpty, setAreFieldsEmpty] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
    cluster: '',
    row_id: '',
  });
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [headerOptions, setHeaderOptions] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [delimiter, setDelimiter] = useState(',');
  const [isNextEnabled, setIsNextEnabled] = useState(false);

  /**
   * ^ Function to handle file upload.
   * @param {*} e - The event object representing the file upload action
   */
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsFileUploaded(true);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          // Get the headers from the parsed CSV
          const headers = results.meta.fields;
          const options = headers.map((header, idx) => ({
            value: header,
            label: header,
            position: idx,
          }));

          setHeaderOptions(options);
          setFileData(results.data);
        },
      });
    } else {
      setIsFileUploaded(false);
    }
    setIsNextEnabled(file && delimiter.trim() !== '');
  };

  /**
   * ^ Function to handle form submission.
   * @param {*} e - The event object representing the form submission action
   */
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  /**
   * Function to handle the back button click.
   */
  const handleBackClick = () => {
    debugger;
    if (visibleSection === 'UploadFile') {
      setVisibleSection('MapFields');
    } else if (visibleSection === 'Import') {
      setVisibleSection('MapFields');
    } else {
      setVisibleSection('UploadFile');
    }
  };

  const percentage = 73;

  /**
   * Function to handle the next button click.
   */
  const onNextClick = () => {
    if (visibleSection === 'UploadFile') {
      if (isFileUploaded) {
        setVisibleSection('MapFields');
      }
    } else if (visibleSection === 'MapFields') {
      setVisibleSection('Import');
    }
  };

  /**
   * Function to handle the click action.
   */
  const handleClick = () => {
    const Name = formData.name.position;
    const Email = formData.email.position;
    const Phoneno = formData.phone.position;
    const Role = formData.role.position;
    const Cluster = formData.cluster.position;
    let userListArray = [];

    for (const user_details of fileData) {
      let tempObj = {
        name: user_details[headerOptions[Name].label],
        email: user_details[headerOptions[Email].label],
        phone: user_details[headerOptions[Phoneno].label],
        role: user_details[headerOptions[Role].label],
        cluster_id: user_details[headerOptions[Cluster].label]
          .split(';')
          .map((item) => item.trim()),
      };
      userListArray.push(tempObj);
    }

    setUserList([...userList, ...userListArray]);
  };
  /**
   * Hook that sets the initial visible section on component mount.
   */

  useEffect(() => {
    setVisibleSection('UploadFile');
  }, []);

  return (
    <>
      <Modal show={isCSVModalOpen} className="modal_body">
        <div className="Upload_container">
          <div className="Upload_header">
            <h2>Upload CSV</h2>
            <div className="header" ref={headerRef}>
              <button
                type="button"
                className={`header_link ${visibleSection === 'UploadFile' ? 'selected' : ''}`}
                onClick={() => {
                  setVisibleSection('UploadFile');
                }}
                disabled={!isNextEnabled}>
                UploadFile
              </button>
              <span className="sign_icon">
                <img src={g_icon} alt="g_icon" />
              </span>
              <button
                type="button"
                className={`header_link ${visibleSection === 'MapFields' ? 'selected' : ''}`}
                onClick={() => {
                  setVisibleSection('MapFields');
                }}
                disabled={!isNextEnabled}>
                MapFields
              </button>
              <span className="sign_icon">
                <img src={g_icon} alt="g_icon" />
              </span>
              <button
                type="button"
                className={`header_link ${visibleSection === 'Import' ? 'selected' : ''}`}
                onClick={() => {
                  setVisibleSection('Import');
                }}
                disabled={!isNextEnabled}>
                Import
              </button>
              <span className="close-icon" onClick={() => setCSVModalOpen(!isCSVModalOpen)}>
                <img src={Close} alt="close" />
              </span>
            </div>
          </div>
          <hr />
          <div className="import_body">
            {visibleSection === 'Import' && (
              <div className="import-container">
                <div className="import-header">
                  <h3>Import Users</h3>
                </div>
                <div className="loader_import">
                  <h2>Start importing user from uploaded csv file....</h2>
                  <ProgressBar
                    variant="progress_check"
                    now={percentage}
                    label={`${percentage}% completed`}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="Upload_body">
            {visibleSection === 'UploadFile' && (
              <>
                <h3>To add User(s) in Bulk, upload a CSV format file to having data in that.</h3>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="csvFile">
                    <Form.Label className="csv_head">
                      UPLOAD CSV FILE <sup>*</sup>
                    </Form.Label>
                    <label htmlFor="file-upload" className="custom-file-upload">
                      <input
                        id="file-upload"
                        type="file"
                        accept=".csv"
                        onChange={handleFileUpload}
                      />
                    </label>
                    <label className="fileupload" for="fileupload">
                      The minimum file size to upload is MB.
                    </label>
                  </Form.Group>
                  <Form.Group controlId="delimiter">
                    <Form.Label className="csv_head">
                      DELIMITER <sup>*</sup>
                    </Form.Label>
                    <Form.Control
                      className=" delimiter"
                      type="text"
                      required
                      value={','}
                      disabled={true}
                      onChange={(e) => {
                        setDelimiter(e.target.value);
                        setIsNextEnabled(isFileUploaded && e.target.value.trim() !== '');
                      }}
                    />
                  </Form.Group>
                </Form>
              </>
            )}

            {visibleSection === 'MapFields' && (
              <div className="mapfield_container">
                <h3>Map fields to CSV file</h3>
                <div className="map_fields">
                  <div className="field_name">
                    <p>MAP FIELD</p>
                    <p className="csv_field">CSV FIELD</p>
                  </div>
                  <hr className="margin_hr" />
                  <Form onSubmit={handleSubmit}>
                    <div className="form-row">
                      <div className="form-group">
                        <label className="col-4" htmlFor="name">
                          Name of the User
                        </label>
                        <span className="front_arrow">
                          <img src={front_arrow} alt="arrow" />
                        </span>
                        <Select
                          id="name"
                          name="name"
                          className="react-select"
                          classNamePrefix="react-select"
                          value={formData.name}
                          onChange={(selectedOption) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              name: selectedOption,
                            }));
                          }}
                          options={headerOptions}
                          styles={colourStyles}
                        />
                      </div>
                      <div className="form-group">
                        <label className="col-4" htmlFor="user">
                          Email
                        </label>
                        <span className="front_arrow">
                          <img src={front_arrow} alt="arrow" />
                        </span>
                        <Select
                          id="user"
                          name="user"
                          className="react-select"
                          classNamePrefix="react-select"
                          value={formData.email}
                          onChange={(selectedOption) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              email: selectedOption,
                            }));
                          }}
                          options={headerOptions}
                          styles={colourStyles}
                        />
                      </div>
                      <div className="form-group">
                        <label className="col-4" htmlFor="phone">
                          Phone Number
                        </label>
                        <span className="front_arrow">
                          <img src={front_arrow} alt="arrow" />
                        </span>
                        <Select
                          id="phone"
                          name="phone"
                          className="react-select"
                          classNamePrefix="react-select"
                          value={formData.phone}
                          onChange={(selectedOption) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              phone: selectedOption,
                            }));
                          }}
                          options={headerOptions}
                          styles={colourStyles}
                        />
                      </div>
                      <div className="form-group">
                        <label className="col-4" htmlFor="role">
                          Role
                        </label>
                        <span className="front_arrow">
                          <img src={front_arrow} alt="arrow" />
                        </span>
                        <Select
                          id="role"
                          name="role"
                          className="react-select"
                          classNamePrefix="react-select"
                          value={formData.role}
                          onChange={(selectedOption) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              role: selectedOption,
                            }));
                          }}
                          options={headerOptions}
                          styles={colourStyles}
                        />
                      </div>
                      <div className="form-group">
                        <label className="col-4" htmlFor="cluster">
                          Cluster(s)
                        </label>
                        <span className="front_arrow">
                          <img src={front_arrow} alt="arrow" />
                        </span>
                        <Select
                          id="cluster"
                          name="cluster"
                          className="react-select"
                          classNamePrefix="react-select"
                          value={formData.cluster}
                          onChange={(selectedOption) => {
                            setFormData((prevFormData) => ({
                              ...prevFormData,
                              cluster: selectedOption,
                            }));
                          }}
                          options={headerOptions}
                          styles={colourStyles}
                        />
                      </div>
                    </div>
                    {areFieldsEmpty && (
                      <p style={{ color: 'red' }}>*Please map all the fields to your CSV</p>
                    )}
                  </Form>
                </div>
              </div>
            )}
          </div>
          <div className="Upload_footer">
            {visibleSection === 'UploadFile' && (
              <div className="btn-submit">
                <Button
                  className={`next-btn ${isClicked ? 'clicked' : ''}`}
                  onClick={onNextClick}
                  disabled={!isNextEnabled}>
                  Next
                </Button>
                <Button
                  className={`back-btn ${isClicked ? 'clicked' : ''}`}
                  onClick={() => setCSVModalOpen(!isCSVModalOpen)}>
                  Back
                </Button>
              </div>
            )}
            {visibleSection === 'MapFields' && (
              <div className="btn-submit">
                <Button
                  className={`next-btn-button ${isClicked ? 'clicked' : ''}`}
                  onClick={() => {
                    if (
                      formData.name.length === 0 ||
                      formData.email.length === 0 ||
                      formData.phone.length === 0 ||
                      formData.role.length === 0 ||
                      formData.cluster.length === 0
                    ) {
                      setAreFieldsEmpty(true);
                    } else {
                      setAreFieldsEmpty(false);
                      handleClick();
                      setCSVModalOpen(!isCSVModalOpen);
                    }
                  }}>
                  Add to Summary
                </Button>

                <Button
                  className={`back-btn-button ${isClicked ? 'clicked' : ''}`}
                  onClick={handleBackClick}>
                  Back
                </Button>
              </div>
            )}
            {visibleSection === 'Import' && (
              <div className="btn-submit">
                <Button className={`import-btn ${isClicked ? 'clicked' : ''}`} onClick={() => {}}>
                  Begin Import
                </Button>

                <Button
                  variant="primary"
                  className={`back-btn-button ${isClicked ? 'clicked' : ''}`}
                  onClick={handleBackClick}>
                  Back
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UploadCsvModal;
