import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

import LandingPage from '../containers/landing-page';
import Login from '../containers/login';
import Register from '../containers/register';
import ForgotPassword from '../containers/forgot-password';
import Sidebar from '../pages/sidebar';

const Routers = () => {
  return (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/forgotPassword" element={<ForgotPassword />} />
      <Route
        exact
        path="/admin"
        element={
          <ProtectedRoute>
            <Sidebar />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default Routers;
