import { getRequest } from '../utils/http-helper';
import { GET_FORMS } from '../utils/url-helpers';
import { toast } from 'react-toastify';

export const sendGetDataFormsUser = async () => {
  try {
    return await getRequest({
      url: GET_FORMS,
      authHeader: true,
    });
  } catch (error) {
    toast.error(error);
  }
};
