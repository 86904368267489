import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

// CSS
import './index.css';

// Image
import Close from '../../assets/images/cross.svg';

// GIF
import Approved from '../../assets/images/gif/approved.gif';
import Rejected from '../../assets/images/gif/rejected.gif';

import { getRejectReason } from '../../services/data-explorer-services';

const confirmModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
  },
};

const ApprovedRejectedModal = ({ openConfirmation, setOpenConfirmation, rejectedReason }) => {
  const [reasonId, setReasonId] = useState();

  const [rejectedReasonData, setRejectedReasonData] = useState([]);

  useEffect(() => {
    if (openConfirmation.reasonRejectionStatus) {
      fetchRejectReasonData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openConfirmation]);

  const fetchRejectReasonData = async () => {
    const { data, error } = await getRejectReason();
    setRejectedReasonData(data);
    if (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const closeConfirmationModal = () => {
    setReasonId();
    setOpenConfirmation({
      ...openConfirmation,
      reasonRejectionStatus: false,
    });
  };

  const handledReason = (reason) => {
    setReasonId(reason?.id);
  };

  return (
    <Modal
      isOpen={openConfirmation.reasonRejectionStatus}
      style={confirmModalStyles}
      onRequestClose={closeConfirmationModal}
      contentLabel="Approveed/Rejected">
      {openConfirmation.confirmationType === 'Rejected' && (
        <>
          {openConfirmation.reasonRejectionPop ? (
            <div className="confirm-reject-modal">
              <div className="reason-title">
                <label>Reason for Rejection</label>
                <span
                  onClick={() => {
                    closeConfirmationModal();
                  }}>
                  <img src={Close} alt="close" />
                </span>
              </div>
              <ul className="reason-rejection">
                {rejectedReasonData &&
                  rejectedReasonData.length !== 0 &&
                  rejectedReasonData?.data.map((reason) => (
                    <li key={reason.id}>
                      <label className="circle-radio">
                        <input
                          type="radio"
                          id={`checkbox-${reason.id}`}
                          name="radio"
                          onChange={() => {
                            handledReason(reason);
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <span className="reason-text">{reason?.reason}</span>
                    </li>
                  ))}
              </ul>

              <div className="reject-btn-div">
                <button
                  className="reject-btn"
                  onClick={() => {
                    closeConfirmationModal();
                  }}>
                  Cancel
                </button>
                <button
                  className="approve-btn"
                  onClick={() => {
                    rejectedReason(reasonId, openConfirmation.confirmationType);
                  }}
                  disabled={reasonId === undefined}>
                  Proceed
                </button>
              </div>
            </div>
          ) : (
            <div className="confirm-modal">
              <img src={Rejected} alt="Loading..." />
              <p>Geotag is rejected</p>
            </div>
          )}
        </>
      )}
      {openConfirmation.confirmationType === 'Approve' && (
        <div className="confirm-modal">
          <img src={Approved} alt="Loading..." />
          <p>Approved Successfully</p>
        </div>
      )}
    </Modal>
  );
};

export default ApprovedRejectedModal;
