import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { SettingsHeader } from '../settings-components';
import profileLogo from '../../assets/images/user_icon.svg';
import { updateUserDetails } from '../../services/auth-services';
import { toast } from 'react-toastify';
import { getRequest } from '../../utils/http-helper';
import { GET_USER_DETAILS } from '../../utils/url-helpers';
import { getUserGroupId } from '../../utils/localStorage';
import * as constants from '../../utils/constants';

import './profile-settings.css';

const ProfileSettings = () => {
  const [credentials, setCredentials] = useState({
    name: '',
    email: '',
    mobile: '',
    organization: '',
  });
  const [btnStatus, setBtnStatus] = useState(true);

  useEffect(() => {
    fetchProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProfileDetails = async () => {
    try {
      const { data, status } = await getRequest({
        url: GET_USER_DETAILS,
        authHeader: true,
      });

      if (status === 200 && data?.data) {
        setCredentials({
          name: data.data.name,
          email: data.data.email,
          mobile: data.data.mobile,
          organization: data.data.company,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBtnStatus(false);
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { name, email } = credentials;

    try {
      const { status, error } = await updateUserDetails({ name, email });

      if (status === 200) {
        fetchProfileDetails();
        toast.success('User Profile Successfully Updated');
      } else if (status === 401) {
        toast.error(error?.response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const renderInputField = (label, name, disabled) => (
    <div className="profile-details-wrapper-item" key={name}>
      <span className="profile-details-wrapper-label">{label}</span>
      <div className="profile-details-wrapper-input-container">
        <input
          value={credentials[name]}
          type="text"
          className="form-control p-2 profile-details-input"
          name={name}
          id={name}
          disabled={disabled}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );

  const isOrganizationVisible = getUserGroupId(constants.USERGROUP_ID) !== '4';

  return (
    <div className="profile-settings-container">
      <div className="profile-settings-sub-container">
        <SettingsHeader title="Change your profile picture" />

        <section className="profile-settings-section">
          <div className="profile-image-container">
            <img className="profile-image" src={profileLogo} alt="Profile Logo" />
          </div>
          <span className="profile-name-text">{credentials.name}</span>
        </section>
      </div>

      <div className="profile-settings-sub-container">
        <SettingsHeader title="Change your details" />

        <section className="profile-settings-section">
          {renderInputField('Name', 'name', false)}
          {renderInputField('Email', 'email', false)}
          {renderInputField('Mobile', 'mobile', true)}

          {isOrganizationVisible && renderInputField('Organization', 'organization', true)}

          <div className="save-password-button-container">
            <Button
              variant="contained"
              disabled={btnStatus}
              onClick={handleSubmit}
              className="save-button">
              Save
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProfileSettings;
