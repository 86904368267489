import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CropImageDownloader from './cropImages';
import styles from './actionModal.module.css';
import Close from '../../assets/images/Close.svg';
import CheckRed from '../../assets/images/icons/check-red.svg';
import CheckGreen from '../../assets/images/icons/check-green.svg';
import Reload from '../../assets/images/logo/replay.svg';
import { getComments } from '../../services/data-explorer-services';

const ActionModal = ({
  modalPopUpStatus,
  setIsApproveModalOpen,
  approveReject,
  selectedDataForApproval,
}) => {
  const { formFieldsDetails } = useSelector((state) => state.explorer);
  const [userComment, setUserComment] = useState(selectedDataForApproval?.[0]?.user_comment || '');
  const { form_details, imageURL, area, polygon_id } = selectedDataForApproval?.[0] || {};
  const getFilteredData = (form_details, updatedFormFields) => {
    const filteredData = {};
    if (Array.isArray(updatedFormFields)) {
      updatedFormFields.forEach((item) => {
        if (form_details[item.key] !== undefined) {
          filteredData[item.metadata.label] = form_details[item.key];
        }
      });
    }
    return filteredData;
  };

  const handleUpdateClick = async () => {
    let payload = {
      polygon_id: polygon_id,
      user_comment: userComment,
    };

    const { status, error } = await getComments(payload);
    if (status === 200) {
      toast.success('Comments updated Successfully');
    } else {
      toast.error(error?.response?.data?.message);
    }
  };

  const updatedFormFields = formFieldsDetails?.data?.form_fields?.filter(
    (field) => field.metadata.input_type !== 'photo'
  );

  const filteredData = getFilteredData(form_details, updatedFormFields);

  if (!selectedDataForApproval?.[0]) {
    return null;
  }

  return (
    <>
      <div className={styles.action_modal_header_section}>
        <h2 className={styles.crop_common_heading}>Marking Details</h2>
        <label className={styles.close_icon}>
          <img src={Close} alt="Close Icon" onClick={(e) => setIsApproveModalOpen(false)} />
        </label>
      </div>
      <div className={styles.crop_details_container}>
        <div className={`${styles.crop_list_section} ${styles.crop_list_section_left}`}>
          <ul className={styles.common_crop_list}>
            <li>
              <label>Area : </label>
              <span>
                {area ?? '-'} sqm || {area ? (area * 0.000247105).toFixed(1) : '-'} acre
              </span>
            </li>
          </ul>
          <h2 className={styles.crop_common_heading}>Crop Form</h2>
          <ul className={`${styles.common_crop_list} ${styles.crop_details}`}>
            {filteredData &&
              Object.entries(filteredData).map(([key, value]) => (
                <li key={key}>
                  <label>
                    {key}
                    <strong>:</strong>
                  </label>
                  <span>{value ?? '-'}</span>
                </li>
              ))}
            <li>
              <label>
                Comments <strong>:</strong>
              </label>

              <span>
                <textarea
                  type="text"
                  value={userComment}
                  placeholder="Type comments"
                  rows={4}
                  cols={18}
                  className={styles.textarea_comments}
                  onChange={(e) => setUserComment(e.target.value)}
                />
              </span>
            </li>
            <button onClick={handleUpdateClick} className={styles.btn_update_form}>
              <img src={Reload} alt="Reload" />
              <span>Update</span>
            </button>
          </ul>
        </div>
        <div className={styles.crop_list_section}>
          <ul className={styles.crop_images}>
            {imageURL !== undefined && imageURL.length !== 0 ? (
              imageURL.map((cropImg) => (
                <CropImageDownloader key={cropImg.id} cropImagesURL={cropImg} />
              ))
            ) : (
              <h1 className={styles.empty_img}>No Crop Images</h1>
            )}
          </ul>
        </div>
      </div>
      <div className={styles.crop_approve_section}>
        {(() => {
          if (modalPopUpStatus === 'Approve') {
            return (
              <>
                <img src={CheckGreen} alt="CheckGreen" />
                <h1 className={styles.approved}>Approved</h1>
              </>
            );
          }
          if (modalPopUpStatus === 'Rejected') {
            return (
              <>
                <img src={CheckRed} alt="CheckRed" />
                <h1 className={styles.rejected}>Rejected</h1>
              </>
            );
          }
          return (
            <>
              <button
                className={styles.reject_btn}
                onClick={() => {
                  setIsApproveModalOpen(false);
                  approveReject('Rejected');
                }}>
                Reject
              </button>
              <button
                className={styles.approve_btn}
                onClick={() => {
                  setIsApproveModalOpen(false);
                  approveReject('Approve');
                }}>
                Approve
              </button>
            </>
          );
        })()}
      </div>
    </>
  );
};

export default ActionModal;
