import { toast } from 'react-toastify';
import {
  getAdvanceFormDetails,
  getFormClusterUserDetails,
} from '../../services/data-explorer-services';
import { dataExplorerAction } from './data-explorer-slice';

export const getFormClusterUserDetailsData = (id) => {
  return async (dispatch) => {
    try {
      const { data, status, error } = await getFormClusterUserDetails(id);
      if (status === 200) {
        dispatch(dataExplorerAction.doGetFormClusterUserDetails({ data: data?.data }));
      } else {
        toast.error(error?.response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'An error occurred while fetching data.');
    }
  };
};

export const getFormDetailsData = (id) => {
  return async (dispatch) => {
    try {
      const { data, status, error } = await getAdvanceFormDetails(id);
      if (status === 200) {
        dispatch(dataExplorerAction.doGetFormDetails({ data: data?.data }));
      } else {
        toast.error(error?.response?.data?.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || 'An error occurred while fetching form details.'
      );
    }
  };
};
