import { getRequest, postRequest, patchRequest } from '../utils/http-helper';
import { getUserId } from '../utils/localStorage';
import { header } from '../utils/constants';
import {
  GET_USERS,
  UPDATE_GT_POINT_STATUS,
  GET_FARM_DETAILS_IMAGE,
  DATA_FILTERS_URL,
  VISUALIZE_DATA_URL,
  AVAILABLE_DATES,
  GET_REJECT_REASON,
  GET_ADVANCE_FORM_DETAILS,
} from '../utils/url-helpers';

export const getRejectReason = async () => {
  try {
    return await getRequest({
      url: GET_REJECT_REASON,
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};

export const sendGetUserForCluster = async (clusters) => {
  const clusterId = clusters.map((cluster) => cluster.value).join(',');
  let url = `${GET_USERS}${clusterId}`;

  try {
    return await getRequest({
      url: url,
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};

export const getAdvanceFormDetails = async (formId) => {
  try {
    return await getRequest({
      url: GET_ADVANCE_FORM_DETAILS.replace('form_id', formId),
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};

export const getAvailableDates = async (form) => {
  try {
    return await getRequest({
      url: AVAILABLE_DATES.replace('form_id', form),
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};

export const getFormClusterUserDetails = async () => {
  try {
    return await getRequest({
      url: DATA_FILTERS_URL.replace('user_id', getUserId()),
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};

export const getPopupImage = async (polygonId) => {
  let userId = localStorage.getItem('user_id');
  let URL = `${GET_FARM_DETAILS_IMAGE}/${userId}/get-boundary-details/polygon/${polygonId}`;
  try {
    return await getRequest({
      url: URL,
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};

export const getComments = async (payload) => {
  try {
    let userId = localStorage.getItem('user_id');
    let URL = `${GET_FARM_DETAILS_IMAGE}/${userId}/farm-data`;
    return await patchRequest({
      url: URL,
      authHeader: true,
      data: payload,
    });
  } catch (error) {
    return error;
  }
};

export const sendSearchRequest = async (payload) => {
  try {
    return await postRequest({
      url: VISUALIZE_DATA_URL,
      data: payload,
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};

export const updateGtPopintStatus = async (payload) => {
  try {
    return await postRequest({
      url: UPDATE_GT_POINT_STATUS,
      data: payload,
      headers: header,
      authHeader: true,
    });
  } catch (e) {
    return e;
  }
};
