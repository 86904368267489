import {
  LOGIN_URL,
  CLUSTER_USER_URL,
  REGISTER_URL,
  LOGOUT_URL,
  GET_CHANGE_PASS,
  DETAILS_URL,
  FORGOT_PASSWORD_REQ_URL,
  OTP_VERIFI_URL,
  SET_NEW_PASSWORD_URL,
} from '../utils/url-helpers';
import { postRequest, deleteRequest } from '../utils/http-helper';

export const getLogin = async (payload) => {
  try {
    return await postRequest({
      url: LOGIN_URL,
      data: payload,
    });
  } catch (error) {
    return error;
  }
};

export const onRegister = async (payload) => {
  try {
    return await postRequest({
      url: REGISTER_URL,
      data: payload,
    });
  } catch (error) {
    return error;
  }
};

export const sendLogoutRequest = async () => {
  try {
    return await deleteRequest({
      url: LOGOUT_URL,
      refreshHeader: true,
    });
  } catch (error) {
    return error;
  }
};

export const sendClusterUserDetails = async (payload) => {
  try {
    return await postRequest({
      url: CLUSTER_USER_URL,
      data: payload,
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};

export const changePassword = async (payload) => {
  try {
    return await postRequest({
      url: GET_CHANGE_PASS,
      data: payload,
      authHeader: true,
    });
  } catch (e) {
    return e;
  }
};

export const updateUserDetails = async (payload) => {
  try {
    return await postRequest({
      url: DETAILS_URL,
      data: payload,
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};

export const forgotPasswordReq = async (payload) => {
  try {
    return await postRequest({
      url: FORGOT_PASSWORD_REQ_URL,
      data: payload,
    });
  } catch (error) {
    return error;
  }
};

export const otpVerifireq = async (payload) => {
  try {
    return await postRequest({
      url: OTP_VERIFI_URL,
      data: payload,
    });
  } catch (error) {
    return error;
  }
};

export const setNewPassword = async (payload) => {
  try {
    return await postRequest({
      url: SET_NEW_PASSWORD_URL,
      data: payload,
      authHeader: true,
    });
  } catch (error) {
    return error;
  }
};
