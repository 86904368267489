import React from 'react';
import SwitchComponent from '../../switch';
import './switch-with-title.css';

const SwitchWithTitle = ({ title, isChecked, onChange }) => (
  <div className="switch-with-title-container">
    <span className="switch-title">{title}</span>
    <div>
      <span className={isChecked ? 'switch-status-active' : 'switch-status'}>
        {isChecked ? 'Enabled' : 'Disabled'}
      </span>
      <SwitchComponent checked={isChecked} onChange={onChange} />
    </div>
  </div>
);

export default SwitchWithTitle;
