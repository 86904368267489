// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBj2GsWPOl9bYPIP0cY47lARxQKxdYY_Ak',
  authDomain: 'satsure-sparta.firebaseapp.com',
  databaseURL: 'https://satsure-sparta-default-rtdb.firebaseio.com',
  projectId: 'satsure-sparta',
  storageBucket: 'satsure-sparta.appspot.com',
  messagingSenderId: '155287685058',
  appId: '1:155287685058:web:5324334ab4d6ee4255b85b',
  measurementId: 'G-BJKTKW0HPW',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
export { database };
