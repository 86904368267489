import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { isValidPhoneNumber, isPasswordValid } from '../../utils/regex-helper';

import OtpInput from 'react-otp-input';
import { Header } from '../../pages';

import passwordshow from '../../assets/images/Eye.svg';
import passwordhide from '../../assets/images/Eye-closed.svg';
import success_logo from '../../assets/images/done.png';
import check_logo from '../../assets/images/check.png';
import sparta_main_logo from '../../assets/images/logo/sparta_maps_logo.svg';
import SatsureLogo from '../../assets/images/logo/satsure-logo.svg';
import Info from '../../assets/images/icons/Info-circle.svg';
import './index.css';
import { forgotPasswordReq, otpVerifireq, setNewPassword } from '../../services/auth-services';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [hideShowPwd, setHideShowPwd] = useState({
    newPassword: false,
    confPassword: false,
  });
  const [otp, setOtp] = useState('');
  const [mobileNumber, setMobileNumber] = useState();
  const [messageStatus, setMessageStatus] = useState('');
  const [forgotVerifiStatus, setForgotVerifiStatus] = useState('forgot');
  const [otpVerifiBtnStatus, setOtpVerifiBtnStatus] = useState('verifi');
  const [passwordStatus, setPasswordStatus] = useState(true);
  const [passwordRestStatus, setPasswordRestStatus] = useState({
    newpassword: true,
    confirmPassword: true,
  });

  const [setpassword, setSetPassword] = useState({
    newpassword: '',
    confirmPassword: '',
  });

  /**
   * ^ Handles the change event for the password input field
   * @param {*} event - The event object
   */
  const onChangePasswordHandler = (event) => {
    const { name, value } = event.target;
    setSetPassword((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    setPasswordRestStatus((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });
  };

  /**
   * ^ Handles the change event for the mobile number input field
   * @param {*} e - The event object
   */
  const onChangeHandler = (e) => {
    setMobileNumber(e.target.value);
    setMessageStatus('');
  };

  /**
   * Sends a request for the forgot password feature
   */
  const sendForgotPasswordRequest = async () => {
    if (!isValidPhoneNumber(mobileNumber)) {
      toast.error('Please Enter a Valid Phone Number');
      setMessageStatus('failed');
    } else {
      let payload = {
        phone: mobileNumber,
        req_new: true,
      };
      const { status, error } = await forgotPasswordReq(payload);
      if (status === 200) {
        setMessageStatus('success');
        setForgotVerifiStatus('verifi');
        toast.success(' The OTP Sent Successfully');
      } else if (error) {
        setMessageStatus('failed');
        toast.error(error?.response?.data?.message);
      }
    }
  };

  /**
   * Sends a request to verify the entered OTP
   */
  const otpVerifiRequest = async () => {
    let payload = {
      phone: mobileNumber,
      otp: otp,
    };
    const { error, status } = await otpVerifireq(payload);
    if (status === 200) {
      setOtpVerifiBtnStatus('verified');
      setTimeout(() => {
        setForgotVerifiStatus('newPassword');
      }, 1000);
    } else if (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  /**
   * Sends a request to reset the password
   */
  const resetPassword = async () => {
    if (isPasswordValid(setpassword.newpassword)) {
      if (setpassword.newpassword === setpassword.confirmPassword) {
        let payload = {
          phone: mobileNumber,
          password: setpassword.newpassword,
        };
        const { error, status } = await setNewPassword(payload);
        if (status === 200) {
          setPasswordStatus(false);
        } else if (error) {
          toast.error(error?.response?.data?.message);
        }
      } else {
        toast.error('Password did not match');
      }
    } else {
      toast.error(
        'Password should be atleast 8 characters and must contain Upper Case, Lower Case, Number and Special Character'
      );
    }
  };

  return (
    <div className="landing-constainer">
      <Header />
      <div className="common-register-container">
        <div className="sparta-logo-wrapper">
          <div className="sparta-logo">
            <img src={sparta_main_logo} alt="sparta maps logo" />
          </div>
          <div className="powered-by-sections">
            <label className="powered-by-text">Powered By</label>
            <div className="satsure-logo">
              <img src={SatsureLogo} alt="satsure logo" />
            </div>
          </div>
        </div>
        <div className="sparta-content-wrapper">
          {forgotVerifiStatus === 'forgot' ? (
            <>
              <div className="forgot-title">
                <h1>FORGET PASSWORD</h1>
                <p>Enter phone number you use to log in</p>
              </div>
              <div className="form-group forgot-group">
                <label className="common-form-label">Phone Number</label>
                <span className="forgot-phone-number">
                  <input
                    value={mobileNumber}
                    type="number"
                    className="common-form-control"
                    id="phonenumber"
                    name="phonenumber"
                    placeholder="Enter your phone number"
                    onChange={(e) => onChangeHandler(e)}
                  />
                </span>
                <div className="success-error">
                  {messageStatus !== '' ? (
                    <>
                      {messageStatus === 'success' ? (
                        <p className="success">
                          Success!!
                          <br></br>A link will be sent to your phone number to
                          <br></br>
                          reset the password
                        </p>
                      ) : (
                        messageStatus === 'failed' && (
                          <p className="error">
                            Error!!
                            <br></br>
                            The phone number you entered is Incorrect
                            <br></br>
                            or is not associated with SatSure Sparta Maps
                          </p>
                        )
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <div className="forgot-btn">
                <button
                  className="common-btn"
                  disabled={
                    mobileNumber === undefined || mobileNumber === '' || mobileNumber.length !== 10
                  }
                  onClick={() => sendForgotPasswordRequest()}>
                  Reset Password
                </button>
                <label onClick={() => navigate('/login')}>Back to Login</label>
              </div>
            </>
          ) : forgotVerifiStatus === 'verifi' ? (
            <>
              <div className="forgot-title">
                <h1>VERIFICATION</h1>
                <p>Please enter the OTP you received on your phone number.</p>
                <div className="otp-field">
                  <p>
                    Please check your SMS for a 6-digit code and
                    <br></br>
                    enter it here to complete the verification.
                  </p>
                  <div className="otp">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      textInputAction={false}
                      // renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                </div>
                <div className="verifi-btn">
                  {otpVerifiBtnStatus === 'verifi' && (
                    <button
                      className="common-btn"
                      disabled={otp.length !== 6}
                      onClick={() => otpVerifiRequest()}>
                      Verify
                    </button>
                  )}
                  {otpVerifiBtnStatus === 'verified' && (
                    <button className="common-btn green-btn">
                      <label>
                        <img src={check_logo} alt="check_logo"></img>
                      </label>
                      Verified
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : (
            forgotVerifiStatus === 'newPassword' && (
              <>
                {passwordStatus ? (
                  <>
                    <div className="forgot-title">
                      <h1>SET NEW PASSWORD</h1>
                      <p>Enter new password to set up new password</p>
                    </div>
                    <div className="forgot-group-password">
                      <label className="common-form-label">New Password</label>
                      <div className="hover-password">
                        <img src={Info} alt="Info" />
                        <span className="tooltip-pass" id="right-direction">
                          Must contain atleast 8 characters
                          <br />
                          Must contain atleast one Upper case letter
                          <br />
                          Must contain atleast one Lower case letter
                          <br />
                          Must contain atleast one number
                          <br />
                          Must contain atleast one special character
                          <br />
                        </span>
                      </div>
                      <span className="common-password forgot-phone-number">
                        <input
                          value={setpassword.newpassword}
                          type={`${hideShowPwd.newPassword ? 'text' : 'password'}`}
                          className="common-form-control"
                          name="newpassword"
                          placeholder="Enter your new password"
                          onChange={(e) => onChangePasswordHandler(e)}
                        />
                        <img
                          src={hideShowPwd.newPassword ? passwordshow : passwordhide}
                          alt="loginLogo"
                          onClick={() =>
                            setHideShowPwd({
                              ...hideShowPwd,
                              newPassword: !hideShowPwd.newPassword,
                            })
                          }
                          className="passwordIcon"
                        />
                      </span>
                    </div>
                    <div className="forgot-group-password">
                      <label className="common-form-label">Confirm Password</label>
                      <span className="common-password forgot-phone-number">
                        <input
                          value={setpassword.confirmPassword}
                          type={hideShowPwd.confPassword ? 'text' : 'password'}
                          className="common-form-control"
                          name="confirmPassword"
                          placeholder="Enter your confirm password"
                          onChange={(e) => onChangePasswordHandler(e)}
                        />
                        <img
                          src={hideShowPwd.confPassword ? passwordshow : passwordhide}
                          alt="passwordLogo"
                          onClick={() =>
                            setHideShowPwd({
                              ...hideShowPwd,
                              confPassword: !hideShowPwd.confPassword,
                            })
                          }
                          className="passwordIcon"
                        />
                      </span>
                    </div>
                    <div className="new-password-btn">
                      <button
                        className="common-btn"
                        disabled={
                          passwordRestStatus.newpassword === true ||
                          passwordRestStatus.confirmPassword === true ||
                          setpassword.newpassword !== setpassword.confirmPassword
                        }
                        onClick={() => resetPassword()}>
                        Reset Password
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="success-logo">
                      <img src={success_logo} alt="success" />
                    </div>
                    <div className="forgot-title">
                      <h1>PASSWORD RESET</h1>
                    </div>
                    <div className="success-error">
                      <p className="success">
                        Success!!
                        <br></br>
                        Your password has been successfully reset.
                      </p>
                    </div>
                    <div className="new-password-btn">
                      <p className="details-text">Click on login below to go to Log In page.</p>
                      <button className="common-btn" onClick={() => navigate('/login')}>
                        Log In
                      </button>
                    </div>
                  </>
                )}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
