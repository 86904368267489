export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_VERSION = 'v1';
export const AUTH_URL = 'auth';
//auth
export const REFRESH_TOKEN_URL = `${BASE_URL}/${API_VERSION}/${AUTH_URL}/refresh-both-tokens`;
export const CLUSTER_USER_URL = `${BASE_URL}/${API_VERSION}/${AUTH_URL}/new/create-bulk-user`;
// API URLS
export const LOGIN_URL = `${BASE_URL}/${API_VERSION}/${AUTH_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/${API_VERSION}/${AUTH_URL}/logout`;
export const REGISTER_URL = `${BASE_URL}/${API_VERSION}/${AUTH_URL}/new/create-bulk-user`;
export const SET_NEW_PASSWORD_URL = `${BASE_URL}/${API_VERSION}/${AUTH_URL}/otp/forgot-pass-setpass`;
export const OTP_VERIFI_URL = `${BASE_URL}/${API_VERSION}/${AUTH_URL}/otp/verify-otp`;
export const FORGOT_PASSWORD_REQ_URL = `${BASE_URL}/${API_VERSION}/${AUTH_URL}/otp/forgot-password-req`;
export const UPDATE_ROLE_CLUSTER_URL = `${BASE_URL}/${API_VERSION}/${AUTH_URL}/new/update-user-role-cluster`;
export const GET_CHANGE_PASS = `${BASE_URL}/${API_VERSION}/${AUTH_URL}/change-password`;
export const DETAILS_URL = `${BASE_URL}/${API_VERSION}/${AUTH_URL}/update-user-details`;
export const GET_VIEW_ALL_LEADERBOARD = `${BASE_URL}/${API_VERSION}/data/get-user-points-data`;
export const GET_FORMS = `${BASE_URL}/${API_VERSION}/data/get-form-data`;
export const GET_COMPANY_SETTINGS = `${BASE_URL}/${API_VERSION}/data/get-company-settings/`;
export const SETTINGS_DATA_URL = `${BASE_URL}/${API_VERSION}/data/set-company-settings`;
export const DAILY_DATA_URL = `${BASE_URL}/${API_VERSION}/data/get-daily-data?days=`;

// Get Profile user details
export const GET_USER_DETAILS = `${BASE_URL}/${API_VERSION}/data/get-user-details`;
export const DATA_FILTERS_URL = `${BASE_URL}/${API_VERSION}/data/user_id`;
export const DOWNLOAD_DATA_URL = `${BASE_URL}/${API_VERSION}/data/download-data`;
export const VISUALIZE_DATA_URL = `${BASE_URL}/${API_VERSION}/data/visualise-data`;
export const GET_USERS = `${BASE_URL}/${API_VERSION}/data/get-cluster-users?cluster_id=`;
export const GET_CLUSTERS = `${BASE_URL}/${API_VERSION}/data/user_id/get-clusters`;
export const GET_HOME_PAGE_DATA = `${BASE_URL}/${API_VERSION}/data/get-home-page-data/`;
export const GET_USER_CLUSTER_DATA = `${BASE_URL}/${API_VERSION}/data/user-cluster-management/user_id`;
export const NON_ADMIN_USERS_URL = `${BASE_URL}/${API_VERSION}/data/get-non-admin-users`;
export const UPDATE_CLUSTER_URL = `${BASE_URL}/${API_VERSION}/auth/update-cluster-user`;
export const UPDATE_USER_CLUSTER_URL = `${BASE_URL}/${API_VERSION}/auth/update-user-role-cluster`;

// creating task list
export const GET_CREATE_TASK_LIST = `${BASE_URL}/${API_VERSION}/data/get-task-list`;
export const GET_CLUSTER_URL = `${BASE_URL}/${API_VERSION}/data`;
export const CREATE_TASK_URL = `${BASE_URL}/${API_VERSION}/data/create-task`;

//Update Gt Points
export const UPDATE_GT_POINT_STATUS = `${BASE_URL}/${API_VERSION}/spartamaps/update-gt-point-status`;
export const AVAILABLE_DATES = `${BASE_URL}/${API_VERSION}/data/form_id/form-available-date`;

export const GET_FARM_DETAILS_IMAGE = `${BASE_URL}/${API_VERSION}/spartamaps`;

// Get Rejection Reasons
export const GET_REJECT_REASON = `${BASE_URL}/${API_VERSION}/spartamaps/get-rejection-reasons`;

//Get Company Forms
export const GET_ADVANCE_FORM_DETAILS = `${BASE_URL}/${API_VERSION}/data/form_id/form-details`;
