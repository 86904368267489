import { PHONE_NUM_REGEX, EMAIL_REGEX, PASSWORD_REGEX } from './constants';

export const isValidPhoneNumber = (phoneNumber) => {
  const regex = new RegExp(PHONE_NUM_REGEX);
  let isUsernamevalid = regex.test(phoneNumber);
  return isUsernamevalid;
};

export const isValidEmail = (email) => {
  const email_regex = new RegExp(EMAIL_REGEX);
  let isEmailValid = email_regex.test(email);
  return isEmailValid;
};

export const isPasswordValid = (password) => {
  const password_regex = new RegExp(PASSWORD_REGEX);
  let isPasswordValid = password_regex.test(password);
  return isPasswordValid;
};
