import React from 'react';
import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const SwitchComponent = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" {...props} />
))(({ theme }) => {
  return {
    [theme.breakpoints.down(768)]: {
      width: 40,
      height: 20,
      marginLeft: 5,
    },
    [theme.breakpoints.between(768, 1024)]: {
      width: 48,
      height: 24,
      marginLeft: 10,
    },
    [theme.breakpoints.up(1024)]: {
      width: 56,
      height: 28,
      marginLeft: 15,
    },
    padding: 0,
    '& .MuiSwitch-switchBase': {
      margin: 2,
      padding: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        [theme.breakpoints.down(768)]: {
          transform: 'translateX(20px)',
        },
        [theme.breakpoints.between(768, 1024)]: {
          transform: 'translateX(24px)',
        },
        [theme.breakpoints.up(1024)]: {
          transform: 'translateX(28px)',
        },
        color: '#E0E0E0',
        '& + .MuiSwitch-track': {
          backgroundColor: '#FFF',
          borderColor: '#04AC0B',
          opacity: 1,
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: '#04AC0B',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      boxSizing: 'border-box',
      [theme.breakpoints.down(768)]: {
        width: 12,
        height: 12,
      },
      [theme.breakpoints.between(768, 1024)]: {
        width: 16,
        height: 16,
      },
      [theme.breakpoints.up(1024)]: {
        width: 20,
        height: 20,
      },
      backgroundColor: '#E0E0E0',
    },
    '& .MuiSwitch-track': {
      borderColor: '#E0E0E0',
      borderWidth: 2,
      borderStyle: 'solid',
      [theme.breakpoints.down(768)]: {
        borderRadius: 10,
      },
      [theme.breakpoints.between(768, 1024)]: {
        borderRadius: 12,
      },
      [theme.breakpoints.up(1024)]: {
        borderRadius: 14,
      },
      backgroundColor: '#fff',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  };
});

export default SwitchComponent;
