import { styled } from '@mui/material/styles';
import { PickersDay } from '@mui/x-date-pickers';

const StyledDay = styled(PickersDay)(({ isHighlighted }) => ({
  ...(isHighlighted && {
    backgroundColor: '#27AE60',
    borderRadius: '8px',
    color: '#fff',
    '&:hover, &:focus': {
      backgroundColor: '#262261',
    },
  }),
}));

export default StyledDay;
