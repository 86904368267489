import React from 'react';
import Modal from 'react-bootstrap/Modal';
import satsureLogo from '../../assets/images/logo/sparta_maps_logo.svg';
import './form-template-modal.css';

const FormTemplateModal = ({ showFormModal, onHide, formDetail }) => (
  <Modal className="form-template" show={showFormModal} onHide={onHide} centered>
    <Modal.Header className="form-template-header">
      <div className="form-template-header-container">
        <span className="form-template-name" data-testid="form-name">
          {formDetail?.form_name}
        </span>

        <span className="form-template-source">SatSure Sparta Maps</span>
      </div>
    </Modal.Header>

    <Modal.Body className="form-template-body">
      {formDetail?.form_data?.form_fields?.map((item, index) => {
        const { input_type, label, data } = item.metadata;
        const textBoxHeight = label === 'Remarks' ? '60px' : '30px';
        const fieldLabel = (
          <span>
            {item.order + 1}. {label}
            {item.required && <span className="mandatory-indicator"> *</span>}
          </span>
        );

        const renderInputType = () => {
          switch (input_type) {
            case 'text_box':
              return (
                <div
                  className="form-template-field-text-box"
                  style={{ height: textBoxHeight }}></div>
              );

            case 'dropdown':
              return (
                <div className="form-template-field-dropdown" data-testid={`dropdown-${index}`}>
                  {data.map((dropdownItem) => (
                    <div key={dropdownItem} className="form-template-field-dropdown-item">
                      <div className="form-template-field-dropdown-item-circle" />
                      <span>{dropdownItem}</span>
                    </div>
                  ))}
                </div>
              );

            case 'date_picker':
              return (
                <div
                  className="form-template-field-date"
                  style={{ height: textBoxHeight }}
                  data-testid={`date-picker-${index}`}>
                  <span>Select Date</span>
                </div>
              );

            case 'photo':
              return (
                <div
                  className="form-template-field-date"
                  style={{ height: textBoxHeight }}
                  data-testid={`photo-field-${index}`}>
                  <span>Add Photo</span>
                </div>
              );

            default:
              return null;
          }
        };

        return (
          <div key={fieldLabel} className="form-template-body-container">
            <span className="form-template-field-text" data-testid={`field-label-${index}`}>
              {fieldLabel}
            </span>
            {renderInputType()}
          </div>
        );
      })}
    </Modal.Body>

    <Modal.Footer className="form-template-footer">
      <div className="form-template-footer-container">
        <img className="form-popup-logo" src={satsureLogo} alt="logo" data-testid="company-logo" />

        <span>SatSure Analytics India Private Limited</span>
      </div>
    </Modal.Footer>
  </Modal>
);

export default FormTemplateModal;
