import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const SingleSelectPicker = ({
  options = [],
  label = '',
  handleChange,
  value = null,
  disabled = false,
}) => {
  const handleAutocompleteChange = (event, newValue) => {
    if (newValue === null) {
      handleChange(null);
    } else {
      handleChange(newValue);
    }
  };

  return (
    <Autocomplete
      value={value}
      disablePortal
      options={options}
      sx={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={handleAutocompleteChange}
      disabled={disabled}
      clearOnEscape
    />
  );
};

export default SingleSelectPicker;
