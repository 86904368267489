import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import StyledDay from './styledDay';

const CommonDatePicker = ({
  availableDates = [],
  selectedDate = null,
  title = '',
  handleDateChange,
  disabled = false,
}) => {
  const renderDay = (day, _selectedDate, pickersDayProps) => {
    const date = dayjs(day).format('YYYY-MM-DD');
    const isHighlighted = availableDates.includes(date);

    return <StyledDay {...pickersDayProps} day={day} isHighlighted={isHighlighted} />;
  };

  const dateValue = selectedDate ? dayjs(selectedDate) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={title}
        value={dateValue}
        onChange={(newDate) => handleDateChange(newDate ? newDate.format('YYYY-MM-DD') : null)}
        sx={{ width: '100%' }}
        slots={{
          day: (props) => renderDay(props.day, props.selectedDate, props),
        }}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default CommonDatePicker;
