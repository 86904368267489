import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { SettingsHeader } from '../settings-components';
import { changePassword } from '../../services/auth-services';
import { toast } from 'react-toastify';

// Images
import ic_password_hide from '../../assets/images/Eye-closed.svg';
import ic_password_show from '../../assets/images/Eye.svg';

// CSS
import './password-settings.css';

export default function PasswordSettings() {
  const [hideOldPassword, setHideOldPassword] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const [credentials, setCredentials] = useState({
    oldPassword: '',
    newPassword: '',
    confirmedPassword: '',
    phone: '',
  });
  const [validPassword, setValidPassword] = useState(false);

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validatePasswords = () => {
    const { oldPassword, newPassword, confirmedPassword } = credentials;
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const isOldPasswordValid = passwordRegex.test(oldPassword);
    const isNewPasswordValid = passwordRegex.test(newPassword);
    const isConfirmedPasswordValid = newPassword === confirmedPassword;

    return isOldPasswordValid && isNewPasswordValid && isConfirmedPasswordValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (credentials.oldPassword.trim() === credentials.newPassword.trim()) {
      toast.error('Old & New Password are the same');
      return;
    }

    const payload = {
      newPassword: credentials.newPassword,
    };

    try {
      const { status, error } = await changePassword(payload);
      if (status === 200) {
        toast.success('Password Updated Successfully');
      } else {
        toast.error(error?.response?.data?.message || 'Password update failed');
      }
    } catch (error) {
      toast.error('An error occurred while updating the password');
    }
  };

  useEffect(() => {
    setValidPassword(validatePasswords());
  }, [credentials]);

  return (
    <>
      <SettingsHeader title={'Change your password'} />

      <section className="password-settings-section">
        <div className="password-form-wrapper">
          <div className="password-form-wrapper-item">
            <span className="password-form-wrapper-label">Enter old password</span>
            <div className="password-form-wrapper-input-container">
              <input
                type={hideOldPassword ? 'password' : 'text'}
                className="form-control p-2 password-input"
                name="oldPassword"
                id="oldPassword"
                onChange={onChangeHandler}
                aria-label="Old Password"
              />
              <img
                src={hideOldPassword ? ic_password_hide : ic_password_show}
                alt="old password eye"
                onClick={() => setHideOldPassword((prev) => !prev)}
                className="password-eye"
              />
            </div>
          </div>

          <div className="password-form-wrapper-item">
            <span className="password-form-wrapper-label">New password</span>
            <div className="password-form-wrapper-input-container">
              <input
                type={hideNewPassword ? 'password' : 'text'}
                className="form-control p-2 password-input"
                name="newPassword"
                id="newPassword"
                onChange={onChangeHandler}
                aria-label="New Password"
              />
              <img
                src={hideNewPassword ? ic_password_hide : ic_password_show}
                alt="new password eye"
                onClick={() => setHideNewPassword((prev) => !prev)}
                className="password-eye"
              />
            </div>
          </div>

          <div className="password-form-wrapper-item">
            <span className="password-form-wrapper-label">Re-enter password</span>
            <div className="password-form-wrapper-input-container">
              <input
                type={hideConfirmPassword ? 'password' : 'text'}
                className="form-control p-2 password-input"
                name="confirmedPassword"
                id="confirmedPassword"
                onChange={onChangeHandler}
                aria-label="Confirm Password"
              />
              <img
                src={hideConfirmPassword ? ic_password_hide : ic_password_show}
                alt="re-enter password eye"
                onClick={() => setHideConfirmPassword((prev) => !prev)}
                className="password-eye"
              />
            </div>
          </div>

          <div className="update-password-button-container">
            <Button
              variant={'contained'}
              disabled={!validPassword}
              onClick={handleSubmit}
              className="update-button">
              Update
            </Button>
          </div>
        </div>

        <div className="validate-pass-container">
          <span className="validate-pass-container-title">Password requirements</span>
          <ul>
            <li>Must contain at least 8 characters</li>
            <li>Must contain at least one Upper case letter</li>
            <li>Must contain at least one Lower case letter</li>
            <li>Must contain at least one number</li>
            <li>Must contain at least one special character</li>
          </ul>
        </div>
      </section>
    </>
  );
}
