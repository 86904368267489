import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Header from '../../pages/header';

import './login.css';

import passwordShowIcon from '../../assets/images/Eye.svg';
import passwordHideIcon from '../../assets/images/Eye-closed.svg';
import spartaMainLogo from '../../assets/images/logo/sparta_maps_logo.svg';
import satsureLogo from '../../assets/images/logo/satsure-logo.svg';
import * as constants from '../../utils/constants';

import { getLogin } from '../../services/auth-services';
import { isValidPhoneNumber } from '../../utils/regex-helper';

const Login = () => {
  const navigate = useNavigate();
  const [hideShowPwd, setHideShowPwd] = useState(true);
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginLoader, setLoginLoader] = useState(false);

  const isLoginDisabled = !credentials.username || !credentials.password;

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
    setUsernameError('');
    setPasswordError('');
  };

  const validateCredentials = () => {
    let isValid = true;
    if (!credentials.username) {
      setUsernameError('Please Enter Username');
      isValid = false;
    } else if (!isValidPhoneNumber(credentials.username)) {
      setUsernameError('Please Enter Valid Mobile Number');
      isValid = false;
    }

    if (!credentials.password) {
      setPasswordError('Please Enter Password');
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateCredentials()) {
      sendLoginRequest();
      setLoginLoader(true);
    }
  };

  const sendLoginRequest = async () => {
    const payload = {
      mobile: credentials.username,
      password: credentials.password,
    };

    const { data, status, error } = await getLogin(payload);
    if (status === 200) {
      localStorage.setItem(constants.ACCESS_TOKEN, data.data.accessToken);
      localStorage.setItem(constants.REFRESH_TOKEN, data.data.refreshToken);
      localStorage.setItem(constants.USERNAME, data.data.userName);
      localStorage.setItem(constants.USEREMAIL, data.data.userEmail);
      localStorage.setItem(constants.USER_ID, data.data.userId);
      localStorage.setItem(constants.USERGROUP_ID, data.data.userGroupId);
      localStorage.setItem(constants.COMPANY_ID, data.data.userCompany);
      navigate('/admin', { replace: true });
      toast.success('Successfully Logged In');
    } else if (error) {
      toast.error(error?.response?.data?.message);
    }
    setLoginLoader(false);
  };

  return (
    <div className="landing-container">
      <Header />
      <div className="common-register-container login-container">
        <div className="sparta-logo-section">
          <div className="satsure-sparta-logo">
            <div className="logo-wrapper">
              <img className="landing-page-logo" src={spartaMainLogo} alt="Sparta Logo" />
            </div>
            <div className="powered-by-texts">
              <label>Powered By</label>
            </div>
            <div className="satsure-logo">
              <img src={satsureLogo} alt="Satsure Logo" />
            </div>
          </div>
        </div>
        <div className="login-form-container">
          <h3 className="login-header">LOGIN</h3>
          <form className="login-form" onSubmit={handleSubmit}>
            <div className="form-group username-input">
              <label className="login-form-label" htmlFor="username">
                Username
              </label>
              <input
                type="text"
                className="form-control login-input p-2"
                id="username"
                name="username"
                placeholder="Enter username"
                onChange={onChangeHandler}
              />
              {usernameError && <span className="login-error-message">{usernameError}</span>}
            </div>
            <div className="form-group password-input-container">
              <label className="login-form-label" htmlFor="password">
                Password
              </label>
              <div className="login-password-container">
                <input
                  type={hideShowPwd ? 'password' : 'text'}
                  className="form-control p-2 password-input"
                  name="password"
                  id="password"
                  placeholder="Enter password"
                  onChange={onChangeHandler}
                />
                <img
                  src={hideShowPwd ? passwordHideIcon : passwordShowIcon}
                  alt="Toggle Password Visibility"
                  onClick={() => setHideShowPwd((prev) => !prev)}
                  className="password-eye"
                />
              </div>
              {passwordError && <span className="login-error-message">{passwordError}</span>}
            </div>
            <div className="forgot-password">
              <a className="forgot-password-link" href="/forgotPassword">
                Forgot Password?
              </a>
            </div>
            <div className="sign-up">
              Don't have an account?
              <Link className="sign-up-link" to="/register">
                Sign up
              </Link>
            </div>
            <button type="submit" className="btn login-submit-button" disabled={isLoginDisabled}>
              <strong>LOGIN</strong>
              {loginLoader && (
                <span className="circle-progress">
                  <CircularProgress color="inherit" />
                </span>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
